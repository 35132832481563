import React, { useState } from 'react';
import './MapStyleDialog.css';
import { MapStyleSelection } from './MapStyleSelection';

export default function MapStyleHelp({ cgv }) {

  return (
    <div className="MapStyleHelp">
      <div className='dialog-header'>
        <div><strong>Overview</strong></div>
        <ul>
          <li>A Map Style is a collection of visual settings (e.g., legend colors, label fonts, etc) that can be exported from one map and imported into another using a JSON file.</li>
        </ul>
        <div><strong>Export</strong></div>
        <ul>
          <li>The Export tab displays all the settings that can be exported from the current map. You can choose to export all settings or only specific ones of interest.</li>
          <li>The exported settings reflect the map as it is currently displayed, including any unsaved changes.</li>
        </ul>
        <div><strong>Import</strong></div>
        <ul>
          <li>Before importing styles, we recommend saving any changes to your map. This allows you to discard unwanted changes by reloading the map page if needed.</li>
        </ul>
        <div><strong>Missing Style Settings</strong></div>
        <ul>
          <li>
          If there are additional settings you would like to see included in the export/import process, please share your suggestions by emailing
            <span>&nbsp;</span><a href="mailto:info@proksee.ca?subject=Proksee%20-%20Map%20Style">Proksee Info</a>.
          </li>
        </ul>
      </div>
    </div>
  );
};

