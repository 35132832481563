let instance = null;

class ServerAPI {
  constructor(projectID) {
    if(!instance){
      instance = this;
    }
    this._projectID = projectID;
    this._crsf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    return instance;
  }

  get projectID() {
    return this._projectID;
  }

  get URL() {
    return {
      jobCreate: `/projects/${this.projectID}/jobs`,
      jobList: `/projects/${this.projectID}/jobs.json`,
      snapshotCreate: `/projects/${this.projectID}/snapshots`,
      snapshotList: `/projects/${this.projectID}/snapshots.json`,
      projectList: `/projects.json`,
      projectSave: `/projects/${this.projectID}/save`,
      projectShow: `/projects/${this.projectID}.json`,
      clientToolRun: `/projects/${this.projectID}/client_tool_run.json`,
      logAddToMap: `/projects/${this.projectID}/log_add_to_map.json`,
      downloadAction: `/projects/${this.projectID}/download_action.json`,
      helpPaths: `/help.json`,
    }
  }

  // FIXME: this cleaned up to work if action and id are not provided
  // urlFor({controller, action, id, params}) {
  //   return `/${controller}/${id}/${action}`;
  // }

  jobLog(job) {
    return `/projects/${this.projectID}/jobs/${job.id}/log.json`;
  }

  jobResults(job) {
    return `/projects/${this.projectID}/jobs/${job.id}/results`;
  }

  jobFileTree(job) {
    return `/projects/${this.projectID}/jobs/${job.id}/file_tree.json`;
  }

  jobFile(job) {
    return `/projects/${this.projectID}/jobs/${job.id}/file`;
  }

  embeddedJobFile(job) {
    return `/projects/${this.projectID}/jobs/${job.id}/embedded_file.html`;
  }

  downloadJobFile(job) {
    return `/projects/${this.projectID}/jobs/${job.id}/download_file.html`;
  }

  snapshotRestore(snapshot) {
    return `/projects/${this.projectID}/snapshots/${snapshot.uuid}/cgview.json`;
  }

  // params = {test: 42, time: 'now'}
  //   -> url?test=42&time=now
  addSearchParams(url='', params={}) {
    url += '?';
    const keys = Object.keys(params);
    for (let i=0, len=keys.length; i<len; i++) {
      const key = keys[i];
      if (i > 0) {
        url += '&';
      }
      // url += `${key}=${params[key]}`
      url += `${key}=${encodeURIComponent(params[key])}`
    }
    return url;
  }

  // Extra options:
  // - formData: if true, the data will not be stringified as it's assumed to be a FormData object
  // - header: can override headers
  async fetch(method, url, data, options={}) {
    let bodyData; // undefined for GET
    let headers = {'Content-Type': 'application/json', 'X-CSRF-Token': this._crsf, 'Accept': 'application/json'};
    if (method !== 'GET') {
      if (options.formData) {
        // Do not set Content-Type. This is set automatically with FormData to mulipart/form-data
        headers = {'X-CSRF-Token': this._crsf, 'Accept': 'application/json'};
        bodyData = data;
        // Remove formData from options, since options are passed to fetch
        delete options.formData;
      } else {
        bodyData = JSON.stringify(data);
      }
    }
    // Add any search params for get requests
    if (method === 'GET' && data) {
      url = this.addSearchParams(url, data);
    }
    // console.log(url)
    let myRequest = new Request(url);
    // console.log(myRequest)
    try {
      const response = await fetch(myRequest, {
        method,
        headers,
        body: bodyData,
        ...options
      });
      // console.log(response)
      // 422 is an unprocessable_entity. This is returned if a problem occurred.
      if (response.ok || response.status === 422) {
        // console.log(response);
        const isJson = response.headers.get('content-type')?.includes('application/json');
        // const json = await response.json();
        const json = isJson ? await response.json() : {};
        const blob = isJson ? null : await response.blob();
        return {json, blob, status: response.status, ok: response.ok};
      } else {
        throw new Error('*** HTTP ERROR, status = ' + response.status);
      }
    } catch (err) {
      console.log(err)
      // Add optional error handling here.
    }

  }

  async get(url, data, options) {
    return this.fetch('GET', url, data, options);
  }

  async post(url, data, options) {
    return this.fetch('POST', url, data, options);
  }

  async patch(url, data, options) {
    return this.fetch('PATCH', url, data, options);
  }

  async delete(url, data) {
    return this.fetch('DELETE', url, data);
  }

  async patchJob(jobID, attributes={}, options) {
    const url = `/projects/${this.projectID}/jobs/${jobID}.json`;
    const data = { job: attributes };
    return this.patch(url, data, options);
  }

  async deleteJob(jobID) {
    const url = `/projects/${this.projectID}/jobs/${jobID}.json`;
    return this.delete(url);
  }

  async deleteJobs(bulkIDs) {
    const url = `/projects/${this.projectID}/jobs/destroy_bulk.json`;
    const data = { bulkIDs };
    return this.delete(url, data);
  }

  async patchSnapshot(snapshotID, attributes={}, options) {
    const url = `/projects/${this.projectID}/snapshots/${snapshotID}.json`;
    const data = { snapshot: attributes };
    return this.patch(url, data, options);
  }

  async deleteSnapshot(snapshotID) {
    const url = `/projects/${this.projectID}/snapshots/${snapshotID}.json`;
    return this.delete(url);
  }

  async deleteSnapshots(bulkIDs) {
    const url = `/projects/${this.projectID}/snapshots/destroy_bulk.json`;
    const data = { bulkIDs };
    return this.delete(url, data);
  }

  async deleteProject(project) {
    const url = `/projects/${project.uuid}.json`;
    return this.delete(url);
  }

  async deleteProjects(bulkIDs) {
    const url = `/projects/destroy_bulk.json`;
    const data = { bulkIDs };
    return this.delete(url, data);
  }

  // async clientToolRun(tool, inputs, errors) {
  //   const data = { tool_version: tool.version, inputs, errors };
  //   return this.post(this.URL.clientToolRun, data);
  // }

  // async post2(url, data) {
  //   let myRequest = new Request(url);
  //   try {
  //     const response = await fetch(myRequest, {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this._crsf, 'Accept': 'application/json' },
  //       body: JSON.stringify(data)
  //     });
  //
  //     // 422 is an unprocessable_entity. This is returned if a problem occurred.
  //     if (response.ok || response.status === 422) {
  //       const json = await response.json();
  //       return {json, status: response.status, ok: response.ok};
  //     } else {
  //       throw new Error('*** HTTP ERROR, status = ' + response.status);
  //     }
  //   } catch (err) {
  //     console.log(err)
  //     // Add optional error handling here.
  //   }
  //
  // }

}

export default ServerAPI;

    // const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    // // const url = `/projects/${cgv.id}/save`;
    // const url = `/projects/${projectID}/save`;
    // const data = { data: JSON.stringify(cgv.io.toJSON()) };
    // let myRequest = new Request(url);
    // fetch(myRequest, {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': csrf },
    //   body: JSON.stringify(data)
    // })
    // .then( response => {
    //   if (!response.ok) {
    //     throw new Error('HTTP error, status = ' + response.status);
    //   }
    //   Message.close();
    // })


  // post(url, data) {
  //   let myRequest = new Request(url);
  //   return fetch(myRequest, {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this._crsf, 'Accept': 'application/json' },
  //     body: JSON.stringify(data)
  //   });
  // }
