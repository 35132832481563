import { combineReducers } from 'redux';
import contigs from './contigs';
import tracks from './tracks';
import captions from './captions';
import features from './features';
import plots from './plots';
import legend from './legend';
import legendItems from './legendItems';
import location from './location';
import viewer from './viewer';
import settings from './settings';
import annotation from './annotation';
import ruler from './ruler';
import centerLine from './centerLine';
import backbone from './backbone';
import sequence from './sequence';
import stats from './stats';
import bookmarks from './bookmarks';
import dividers from './dividers';
import ui from './ui';
import jobs from './jobs';
import snapshots from './snapshots';
import project from './project';

export default combineReducers({
  // cgv,
  viewer,
  project,
  jobs,
  snapshots,
  settings,
  annotation,
  ruler,
  centerLine,
  backbone,
  sequence,
  contigs,
  tracks,
  features,
  plots,
  captions,
  legend,
  legendItems,
  location,
  ui,
  stats,
  bookmarks,
  dividers,
});
