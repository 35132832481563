import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CGViewContext } from '../app/CGViewContext';
import ServerAPI from '../models/ServerAPI';
import './FeaturePane.css'; // Using same CSS as Features
import './SnapshotPane.css'; 
import Toast from '../presenters/Toast';
import DataElement from '../presenters/DataElement';
import DataElementGroup from '../presenters/DataElementGroup';
import TextElement from '../presenters/TextElement';
import TextEditable from '../presenters/TextEditable';
import ImageButton from '../presenters/ImageButton';
import ButtonGroup from '../presenters/ButtonGroup';
import Button from '../presenters/Button';
import Switch from '../presenters/Switch';
import Message from '../presenters/Message';
import FavoriteButton from '../presenters/FavoriteButton';
import SnapshotsDeleteDialog from './dialogs/SnapshotsDeleteDialog';
import ExternalLink from '../presenters/ExternalLink';
import { VirtualTable, Column } from '../presenters/VirtualTable';
import * as helpers from '../support/Helpers';
import '../support/CommonStyles.css';
import './SnapshotPane.css';
import * as CGView from '../../CGView.js';

import store from '../app/store';
import { updateSnapshots, clearSnapshotsReset } from '../actions/snapshots';

// Connected
import { connect } from 'react-redux';

// Interface above the table with buttons
// - "Revert" map before snapshot was opened
//   - Note, if map was dirty before snapshot was opened, this will revert to that state
//   - So saved state will be restored and marked as dirty again (if it was before)
// - "Keep" changes made after snapshot was opened
//   - Clicking this will always mark the map as dirty
// - Clicking keep or revert will close the extra interface
// - Also clicking "Save Changes" will close the extra interface

class SnapshotPane extends Component {

  static propTypes = {
    snapshots: PropTypes.shape({
      ids:  PropTypes.array,
      byID: PropTypes.object,
      reset: PropTypes.bool,
    }).isRequired,
  }

  // static defaultProps = {
  //   snapshots: {},
  // }

  constructor(props) {
    super(props);
    this.state = {
      virtualTableRef: React.createRef(),
      bulkDeleteIDs: [],
      selectedSnapshot: null,
      originalCGVJSON: null,
      originalCGVDirty: false,
    }
    this.infoBoxRenderer = this.infoBoxRenderer.bind(this);
    this.nameCellRenderer = this.nameCellRenderer.bind(this);
    this.sharedCellRenderer = this.sharedCellRenderer.bind(this);
    this.optionCellRenderer = this.optionCellRenderer.bind(this);
  }

  // Get the Snapshots list on mounting component
  // This should already be done (in ProkseeServerDispatch.js) but with large projects
  // it get skipped sometimes. If this does not work when can add a timer.
  componentDidMount() {
    setTimeout( () => {
      this.refreshSnapshots(false);
      // console.log('GET SNAPSHOTS')
    }, 5000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.reset && !prevProps.reset) {
      console.log("Snapshot reset triggered!");
      // Reset the state
      this.setState({originalCGVJSON: null, originalCGVDirty: false, selectedSnapshot: null});
      // Dispatch action to clear the reset state
      this.props.clearReset();
    }
  }

  refreshSnapshots(withMsg=true) {
    const Server = new ServerAPI();
    Server.get(Server.URL.snapshotList)
    .then( response => {
      if (response.ok) {
        // console.log(response.json)
        store.dispatch(updateSnapshots(response.json))
        if (withMsg) {
          Toast.create('Snapshots Refreshed', 3000)
        }
      }
    });
  }

  nameCellRenderer({ rowData:snapshot }) {
    return (
      <div className='column-value'>
        <div className='column-value-main'>{snapshot.name}</div>
        <div className='column-value-sub'>
          <div className='format'>
            {helpers.friendlyDateTime(snapshot.createdAt)}
          </div>
        </div>
        {/* <div className='column-value-sub'>
          <div className='format'> {snapshot.uuid} </div>
        </div> */}
      </div>
    )
  }

  sharedCellRenderer({ rowData:snapshot }) {
    return (
      <div className='column-value'>
        {/* Using key to force re-render of the Switch component, to keep in it sync with the info box
        Not sure why we need to do this, but it works. */}
        <Switch
          // key={Math.random()}
          value={snapshot.shared}
          size='small'
          onChange={(value) => this.onChangeSnapshot({snapshot, value, attribute: 'shared'})}
        />
        <div className='column-value-sub uuid'>
            {snapshot.uuid}
        </div>
      </div>
    )
  }

  // Params: cellData, columnData, columnIndex, dataKey, isScrolling, rowData, rowIndex, 
  optionCellRenderer({ rowData:snapshot }) {
    return (
      <ButtonGroup className='option-buttons'>
        <ImageButton
          imageName='copy'
          disabled={!snapshot.shared}
          title='Copy Share Link'
          onClick={ (value) => this.onCopyShareLink(snapshot) }
        />
        <ImageButton
          imageName='snapshotRestore'
          title='Restore Snapshot'
          onClick={ (value) => this.onRestoreSnapshot(snapshot) }
        />
        <FavoriteButton
          favorite={snapshot.favorite}
          onClick={ (value) => this.onFavoriteSnapshot(snapshot, value) }
        />
      </ButtonGroup>
    )
  }


  infoBoxRenderer(snapshot) {
    return (
      <div>
        <DataElement label='Name' helpPath='help:sidebar:data:snapshots:name'>
          <TextEditable value={snapshot.name}
            onChange={(value) => this.onChangeSnapshot({snapshot, attribute: 'name', value})}/>
        </DataElement>
        <DataElement label='ID' helpPath='help:sidebar:data:snapshots:id'>
          <TextElement>{snapshot.uuid}</TextElement>
        </DataElement>
        {/* <DataElementGroup> */}
        <div className='group-sharing'>
          <DataElement label='Sharing' helpPath='help:sidebar:data:snapshots:sharing'>
            <Switch
              // key={Math.random()}
              value={snapshot.shared}
              // width="80%"
              onChange={(value) => this.onChangeSnapshot({snapshot, value, attribute: 'shared'})}
            />
          </DataElement>
          <DataElement label='.' className='copy-share-link'>
            <ImageButton
              imageName='copy'
              text='Copy Share Link'
              width="100%"
              disabled={!snapshot.shared}
              onClick={ (value) => this.onCopyShareLink(snapshot) }
            />
          </DataElement>
          <DataElement label='Shared Count' helpPath='help:sidebar:data:snapshots:shared_count'>
            <TextElement>{snapshot.sharedCount}</TextElement>
          </DataElement>
        {/* </DataElementGroup> */}
        </div>
        <DataElementGroup>
          <DataElement label='Size' helpPath='help:sidebar:data:snapshots:size'>
            <TextElement>{helpers.formatBytes(snapshot.size)}</TextElement>
          </DataElement>
          <DataElement label='Created At' helpPath='help:sidebar:data:snapshots:created'>
            <TextElement>{helpers.friendlyDateTime(snapshot.createdAt)}</TextElement>
          </DataElement>
        </DataElementGroup>
        <div className='action-buttons'>
          <ImageButton imageName='snapshotRestore' text='Restore' width={100} size='default'
        title='Restore Snapshot' onClick={ () => this.onRestoreSnapshot(snapshot) } />
          <ImageButton imageName='download' text='Download' width={100} size='default'
        title='Download Snapshot Map JSON' onClick={ () => this.onDownloadSnapshot(snapshot) } />
          <ImageButton imageName='delete' text='Delete' width={100} size='default'
        title='Delete Snapshot' onClick={ () => this.onDeleteSnapshot([snapshot.id]) } />
        </div>
      </div>
    )
  }

  // This code should be kept in sync with onLocalClose in SnapshotsAddDialog
  onAddSnapshot() {
    const { cgv } = this.context;
    Message.open({content: "Creating Snapshot..."})
    const Server = new ServerAPI();

    const snapshotNames = helpers.itemsAsArray(this.props.snapshots).map( s => s.name);
    const name = CGView.utils.uniqueId('snapshot-', 1, snapshotNames);
    const cgview = JSON.stringify(cgv.io.toJSON());
    const data = { snapshot: {name, cgview} };
    Server.post(Server.URL.snapshotCreate, data)
    .then( response => {
      if (response.ok) {
        this.refreshSnapshots(false);
        Toast.create('Snapshot successfully created', 1000);
      } else {
        // console.log(response.json)
        this.setState({errors: response.json});
      }
    })
    .finally( () => {
      Message.close();
    })
  }

  // FIXME: confirm restore if there are changes
  onRestoreSnapshot(snapshot) {
    const { cgv } = this.context;
    Message.open({content: "Restoring Snapshot..."})
    const Server = new ServerAPI();
    Server.get(Server.snapshotRestore(snapshot))
    .then( response => {
      // console.log(response)
      if (response.ok) {
        // this.refreshSnapshots(false);
        this.setState({originalCGVJSON: cgv.io.toJSON(), originalCGVDirty: cgv.dataHasChanged, selectedSnapshot: snapshot});
        cgv.io.loadJSON(response.json);
        cgv.update({dataHasChanged: true});
        Toast.create('Snapshot successfully restored', 1000);
      } else {
        // dialog.showErrors(response.json);
      }
    })
    .finally( () => {
      Message.close();
    })
  }

  shareLink(snapshot) {
    return `${window.location.origin}/shared/${snapshot.uuid}`;
  }

  onCopyShareLink(snapshot) {
    navigator.clipboard.writeText(this.shareLink(snapshot));
    Toast.create('Share Link Copied to the Clipboard', 1500)
  }

  onFavoriteSnapshot(snapshot, favorite) {
    this.onChangeSnapshot({snapshot, attribute: 'favorite', value: favorite});
  }

  onDownloadSnapshot(snapshot) {
    const { cgv } = this.context;
    Message.open({content: "Downloading Snapshot..."})
    const Server = new ServerAPI();
    Server.get(Server.snapshotRestore(snapshot))
    .then( response => {
      console.log(response)
      if (response.ok) {
        // cgv.io.loadJSON(response.json);
        const downloadJSONString = JSON.stringify(response.json);
        cgv.io.download(downloadJSONString, `${snapshot.name}.json`, 'text/json');
        Server.get(Server.URL.downloadAction, {source: 'snapshot', snapshot: snapshot.uuid});
      } else {
        // dialog.showErrors(response.json);
      }
    })
    .finally( () => {
      Message.close();
    })
  }


  onChangeSnapshot({snapshot, attribute, value}) {
    console.log('Changing Snapshot: ', snapshot.id, attribute, value)
    const Server = new ServerAPI();
    // Server.patchSnapshot(snapshot.id, {[attribute]: value});
    Server.patchSnapshot(snapshot.uuid, {[attribute]: value});
  }

  onDeleteSnapshot(ids) {
    this.setState({bulkDeleteIDs: ids});
  }

  onRevertChanges() {
    const { originalCGVJSON, originalCGVDirty } = this.state;
    const { cgv } = this.context;

    cgv.io.loadJSON(originalCGVJSON);
    if (originalCGVDirty) {
      cgv.update({dataHasChanged: originalCGVDirty});
    }
    this.setState({originalCGVJSON: null, originalCGVDirty: false, selectedSnapshot: null});
    Toast.create('Map Reverted', 1000);
  }

  onKeepChanges() {
    const { originalCGVJSON, selectedSnapshot } = this.state;
    const { cgv } = this.context;
    this.setState({originalCGVJSON: null, originalCGVDirty: false, selectedSnapshot: null});
  }


  renderDeleteDialog() {
    const { bulkDeleteIDs, virtualTableRef } = this.state;
    if (bulkDeleteIDs.length > 0) {
      return (
        <SnapshotsDeleteDialog
          snapshotIDs={bulkDeleteIDs}
          snapshotTableRef={virtualTableRef}
          snapshotsByID={this.props.snapshots.byID}
          onClose={() => this.setState({bulkDeleteIDs: []})}
        />
      );
    }
  }

  renderHeaderHelp() {
    const snapshots = helpers.itemsAsArray(this.props.snapshots);

    if (snapshots.length) {
      return (
        <div className='ps-alert ps-alert-notice original-cgv-status original-cgv-status-closed'></div>
      );
    }

    return (
      <div className='ps-alert ps-alert-notice original-cgv-status'>
        <div className='snapshot-header-help'>
          Snapshots save the current map, allowing you to reload or share it with others. See <ExternalLink name="Help" link="/help#s.help:sidebar:data:snapshots" size={12} />.
        </div>
      </div>
    );
  }

  renderOriginalCGVStatus() {
    const { originalCGVJSON, selectedSnapshot } = this.state;

    if (!originalCGVJSON) {
      return (
        <div className='ps-alert ps-alert-notice original-cgv-status original-cgv-status-closed'></div>
      );
    }

    return (
      <div className='ps-alert ps-alert-notice original-cgv-status'>
        <div className='original-cgv-status-text'>
          Viewing Snapshot: <strong>{selectedSnapshot.name}</strong>
        </div>
        <div className='original-cgv-status-buttons'>
          <Button
            title='Revert Back to Original Map'
            width="120px"
            onClick={ () => this.onRevertChanges() }
          >Revert Changes</Button>
          <Button
            title='Keep Changes'
            width="120px"
            onClick={ () => this.onKeepChanges() }
          >Keep Changes</Button>
        </div>
      </div>
    );
  }

  renderErrors() {
    const { errors } = this.state;

    if (!errors) {
      return (
        <div className='ps-alert ps-alert-danger snapshot-errors snapshot-errors-closed'></div>
      );
    }

    return (
      <div className='ps-alert ps-alert-danger snapshot-errors'>
        {errors.map( e => <p key={e}>{e}</p> )}
        <div className='original-cgv-status-buttons'>
          <Button
            title='Close'
            width="60px"
            onClick={ () => this.setState({errors: null}) }
          >OK</Button>
        </div>
      </div>
    );
  }

  render() {
    const snapshots = helpers.itemsAsArray(this.props.snapshots);

    // const snapshots = this.state.snapshots.list;
    // console.log(this.state.snapshots)
    // console.log(snapshots)
    return (
      <div style={{width: '100%', height: '100%'}} className='SnapshotPane'>
        { this.renderHeaderHelp() }
        { this.renderOriginalCGVStatus() }
        { this.renderErrors() }
        <VirtualTable
          headerHeight={20}
          rowHeight={32}
          infoHeight={275}
          data={snapshots}
          dataName='Snapshots'
          idKey='id'
          infoRenderer={this.infoBoxRenderer}
          selectColumn={true}
          ref={this.state.virtualTableRef}
          deleteAction={(ids)=>{this.onDeleteSnapshot(ids)}}
          addAction={()=>{this.onAddSnapshot()}}
          otherActions={[{
            title: 'Refresh Snapshots',
            imageName: 'refresh',
            position: 'end',
            onClick: () => {this.refreshSnapshots()}
          }]}
        >
          <Column
            label='Snapshot'
            dataKey='name'
            width={170}
            search='nosearch'
            cellRenderer={this.nameCellRenderer}
          />
          <Column
            label='Name'
            dataKey='name'
            hidden={true}
          />
          {/* <Column
            label='Notes'
            dataKey='notes'
            hidden={true}
          /> */}
          <Column
            label='ID'
            dataKey='uuid'
            hidden={true}
          />
          <Column
            label='Size'
            dataKey='size'
            search='number'
            hidden={true}
          />
          <Column
            width={60}
            label='Sharing'
            dataKey='shared'
            cellRenderer={this.sharedCellRenderer}
          />
          <Column
            width={90}
            dataKey=''
            search='nosearch'
            cellRenderer={this.optionCellRenderer}
          />
        </VirtualTable>
        {this.renderDeleteDialog()}
      </div>
    );
  }
}

SnapshotPane.contextType = CGViewContext;

// const ConnectedFormatBar = connect(formatBarMapStateToProps, formatBarMapDispatchToProps)(FormatBar);

//Connected
const snapshotPaneMapStateToProps = (state) => ({ snapshots: state.snapshots, reset: state.snapshots.reset });
const snapshotsMapDispatchToProps = (state) => ({
  clearReset: () => store.dispatch(clearSnapshotsReset()),
});
const ConnectedSnapshotPane = connect(snapshotPaneMapStateToProps, snapshotsMapDispatchToProps, null, {forwardRef: true})(SnapshotPane);

// export default SnapshotPane;
export { SnapshotPane, ConnectedSnapshotPane};

