// MapStyle Format:
  // const mapStyle = {
  //   legendItems: [
  //     {
  //       name: 'CDS',
  //       decoration: 'arrow',
  //       swatchColor: 'rgba(0,110,0,1)',
  //     },
  //     {
  //       name: 'tRNA',
  //       decoration: 'arc',
  //       swatchColor: 'rgba(0,0,110,1)',
  //     },
  //   ],
  //   ruler: {
  //     color: 'rgba(0,0,0,1)',
  //     font: 'sans-serif,plain,12'
  //   },
  // }
  //
  // Map Style By Key where key is 'section-name'
  // {
  //   'legendItem-CDS': {
  //     name: 'CDS',
  //     decoration: 'arrow',
  //     swatchColor: 'rgba(0,110,0,1)',
  //   },
  //   'legendItem-tRNA': {
  //     name: 'tRNA',
  //     decoration: 'arc',
  //     swatchColor: 'rgba(0,0,110,1)',
  //   },
  //  'ruler-color': 'rgba(0,0,0,1)',
  //  'ruler-font': 'sans-serif,plain,12',
  // }

export default class MapStyle {

  constructor(options={}) {
    if (options.json) {
      this._processJSON(options.json);
    } else if (options.cgv) {
      this._processCGV(options.cgv);
    } else if (options.file) {
      // We may not need this
    }
  }

  get mapStyleByKey() {
    return this._mapStyleByKey;
  }

  get allStyleKeys() {
    return this._allStyleKeys;
  }

  keysForSection(section) {
    return this.allStyleKeys.filter((key) => key.startsWith(section));
  }

  _addToMapStyleByKey(mapStyleByKey, section, name, json) {
    if (json?.[section] && name in json[section]) {
      mapStyleByKey[`${section}-${name}`] = json[section][name];
    }
  }

  _processJSON(json) {
    const mapStyleByKey = {};
    // Legend Items
    if (json.legendItems) {
      json.legendItems.forEach((item) => {
        const key = `legendItem-${item.name}`;
        const attributes = ['name', 'decoration', 'swatchColor'];
        mapStyleByKey[key] = this._extractKeys(item, attributes);
      });
    }
    // General Settings
    this._addToMapStyleByKey(mapStyleByKey, 'settings', 'backgroundColor', json);
    this._addToMapStyleByKey(mapStyleByKey, 'settings', 'arrowHeadLength', json);
    this._addToMapStyleByKey(mapStyleByKey, 'settings', 'showShading', json);
    // Label/Annotation Settings
    // FIXME: for label color to work we need to chagne CGView.js to use 'null' instead of 'undefined' for when using feature color
    // this._addToMapStyleByKey(mapStyleByKey, 'labels', 'color', json);
    this._addToMapStyleByKey(mapStyleByKey, 'labels', 'font', json);
    this._addToMapStyleByKey(mapStyleByKey, 'labels', 'onlyDrawFavorites', json);

    this._mapStyleByKey = mapStyleByKey;
    this._allStyleKeys = Object.keys(mapStyleByKey);
    // console.log('MapStyleByKey:', this.mapStyleByKey);
  }

  _processCGV(cgv) {
    const json = {}
    if (cgv.legend?.items) {
      const legend = cgv.legend.toJSON();
      json.legendItems = legend.items;
    }
    json.settings = cgv.settings?.toJSON();
    json.labels = cgv.annotation?.toJSON();
    this._processJSON(json);
  }

  exportJSONForKeys(keys) {
    // console.log('Exporting selected components:', keys);
    const exportStyle = {};
    const mapStyleByKey = this.mapStyleByKey;
    console.log(mapStyleByKey);
    for (const key of keys) {
      let [section, ...rest] = key.split('-');
      const name = rest.join('-');
      if (!(key in mapStyleByKey)) {
        console.error(`Item ${key} not found`);
        continue;
      }
      const item = mapStyleByKey[key];
      // NOTE: this will be either an array (legendItems) or an object (everything else)
      if (section === 'legendItem') {
        section = 'legendItems';
        // Arrays
        if (!exportStyle[section]) {
          exportStyle[section] = [item];
        } else {
          exportStyle[section].push(item);
        }
      } else {
        // Objects
        if (!exportStyle[section]) {
          exportStyle[section] = {};
        }
        exportStyle[section][name] = item;
      }
    }
    return exportStyle;
  }

  _extractKeys(obj, keys) {
    return keys.reduce((result, key) => {
      if (key in obj) {
        result[key] = obj[key];
      }
      return result;
    }, {});
  }

  applyStylesForKeys({keys, cgv}) {
    if (!cgv) {
      console.error('No CGV provided');
      return;
    }
    // const mapStyleByKey = this.mapStyleByKey;
    const itemStyles = this.exportJSONForKeys(keys);
    if (itemStyles.legendItems) {
      for (const item of cgv.legend.items()) {
        const itemStyle = itemStyles.legendItems.find((i) => i.name === item.name);
        if (itemStyle) {
          const attributes = ['decoration', 'swatchColor'];
          const updateData = this._extractKeys(itemStyle, attributes);
          console.log('Updating:', item.name, updateData);
          item.update(updateData);
        }
      }
    }
    if (itemStyles.settings) {
      const attributes = ['backgroundColor', 'arrowHeadLength', 'showShading'];
      const updateData = this._extractKeys(itemStyles.settings, attributes);
      cgv?.settings?.update(updateData);
    }
    if (itemStyles.labels) {
      // const attributes = ['color', 'font', 'onlyDrawFavorites'];
      const attributes = ['font', 'onlyDrawFavorites'];
      const updateData = this._extractKeys(itemStyles.labels, attributes);
      cgv?.annotation?.update(updateData);
    }
    cgv.draw();
  }

  displayNameForKey(key) {
    // Legend Items: return everything after the first dash as is
    if (key.startsWith("legendItem-")) {
        return key.replace(/^legendItem-/, '');
    }

    // Everything else: take the name after the dash, split on capital letters, capitalize each word, and join with a space
    const withoutPrefix = key.replace(/^[^-]*-/, '');
    const words = withoutPrefix.split(/(?=[A-Z])/);
    const result = words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

    return result;
  }

}