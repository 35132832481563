import React from 'react';
import BasicDialog from '../../presenters/BasicDialog';
import ServerAPI from '../../models/ServerAPI';
import Toast from '../../presenters/Toast';

// EXPECTED PROPS:
// - snapshotIDs: array of cgvIDs for the snapshots to delete
// - snapshotsByID: object of all snapshots. Keys are the snapshotID
// - snapshotTableRef: reference to the snapshot Pane VirtualTable
//    - access underlying Table ref with: snapshotTableRef.current.tableRef

class SnapshotsDeleteDialog extends BasicDialog {

  constructor(props, context) {
    super(props, context);
    this.state = { valid: props.snapshotIDs.length > 0 };
  }

  renderTitle() {
    const {snapshotIDs} = this.props;
    return `Delete Snapshots: ${snapshotIDs.length} selected`;
  }

  onLocalClose({action, options}) {
    const {snapshotIDs, snapshotTableRef} = this.props;
    const ids = snapshotIDs;
    if (action === 'ok') {
      ids.map( id => this.context.tabsRef.removeByID(`snapshot-${id}`) );
      const Server = new ServerAPI();
      Server.deleteSnapshots(ids)
      .then( response => {
        snapshotTableRef.current.tableRef.recomputeRowHeights();
        setTimeout( () => snapshotTableRef.current.refreshSelectedIDs());
        Toast.create(`Snapshots Deleted: ${ids.length}`, 1000);
      });
    }
  }

  renderContents() {
    const { snapshotIDs, snapshotsByID  } = this.props;

    let content;
    if (snapshotIDs.length === 0) {
      content = <p>No Snapshots selected (CGView Builder snapshots are ignored).</p>
    } else if (snapshotIDs.length === 1) {
      content = <p>Are you sure you want to delete this snapshot?</p>;
    } else {
      content = <p>Are you sure you want to delete the selected {snapshotIDs.length} snapshots?</p>;
    }

    return (
      <div>
        {content}
      </div>
    );
  }
}

export default SnapshotsDeleteDialog;
