import React from 'react';
import BasicDialog from '../../../presenters/BasicDialog';
import '../../../presenters/DefaultDialog.css';
import Tabs from '../../../presenters/Tabs';
import { Tabs as AntTabs } from 'antd';
import MapStyleImport from './MapStyleImport';
import MapStyleExport from './MapStyleExport';
import MapStyleHelp from './MapStyleHelp';
const TabPane = AntTabs.TabPane;
import * as helpers from '../../../support/Helpers';
import './MapStyleDialog.css';

class MapStyleDialog extends BasicDialog {

  // TODO:
  // - Add additional components to export (e.g. background, ruler, etc.)
  // - Import: Add current map styles as additional column in the tables, so you can see how it will change
  // - Add button to revert to original map style

  constructor(props, context) {
    super(props, context);
    this.state = {
      ...this.state,
      activeKey: '1',
    };
  }

  renderTitle() {
    // return `Import/Export Map Style`;
    return `Map Style - Import/Export`;
  }

  onLocalClose({action, options}) {
    // THIS MAY NOT BE NEEDED
    const cgv = this.cgv;
    if (action === 'ok') { }
    cgv.draw();
  }

  onChangeTab = (activeKey) => {
    this.setState({
      activeKey,
    });
  }

  renderContents() {
    const { activeKey } = this.state;
    const cgv = this.cgv;

    return (
      <div className='MapStyleDialog'>
        {/* <div className='dialog-header'>
          (Beta Testing) Export styles from one map and import to another
        </div> */}
        <Tabs activeKey={activeKey} onChange={this.onChangeTab}>
          <TabPane tab='Import' key='1' closable={false}>
            <MapStyleImport cgv={cgv} />
          </TabPane>
          <TabPane tab='Export' key='2' closable={false}>
            <MapStyleExport cgv={cgv} />
          </TabPane>
          <TabPane tab='Help' key='3' closable={false}>
            <MapStyleHelp />
          </TabPane>
        </Tabs>
      </div>
    )
  }
}

export default MapStyleDialog;
