import consumer from '../../channels/consumer';
import store from './store';
import { updateJobs } from '../actions/jobs';
import { updateSnapshots } from '../actions/snapshots';
import { updateProject } from '../actions/project';
import ServerAPI from '../models/ServerAPI';
// import Toast from '../presenters/Toast';
// import * as helpers from '../support/Helpers';
//
// This file contains all the Proksee server ActionCable channels
// Each channel dispatches the events and data to keep the Redux store in sync.
const initializedProkseeServerDispatchEvents = (projectID) => {

  const Server = new ServerAPI();

  ////////////////////////////////////////////////////////////////////////////////
  // USER
  ////////////////////////////////////////////////////////////////////////////////

  // const refreshState = this.refreshState;
  // consumer.subscriptions.create({channel: "UserChannel"}, {
  //   connected() {
  //     // Called when the subscription is ready for use on the server
  //     console.log('User Connected')
  //   },
  //
  //   disconnected() {
  //     // Called when the subscription has been terminated by the server
  //   },
  //
  //   received(user) {
  //     // Called when there's incoming data on the websocket for this channel
  //     // TODO create debug variable and only print when in debug mode
  //     console.log('User Data Received:')
  //     console.log(user)
  //     refreshState(user);
  //   }
  // });

  // refreshState(user) {
  //   this.setState({
  //     quota:    user.storage_quota,
  //     used:     user.storage_used,
  //     jobCount: user.job_count,
  //     projectCount: user.project_count,
  //   });
  // }


  ////////////////////////////////////////////////////////////////////////////////
  // PROJECTS & JOBS
  ////////////////////////////////////////////////////////////////////////////////


  // Used to get initial list of jobs
  function refreshJobs() {
    Server.get(Server.URL.jobList)
    .then( response => {
      if (response.ok) {
        store.dispatch(updateJobs(response.json))
      }
    })
  }

  // Used to get initial list of snapshots
  function refreshSnapshots() {
    Server.get(Server.URL.snapshotList)
    .then( response => {
      if (response.ok) {
        store.dispatch(updateSnapshots(response.json))
      }
    })
  }

  // Used to get initial project state
  function refreshProject() {
    Server.get(Server.URL.projectShow)
    .then( response => {
      if (response.ok) {
        // console.log(response.json)
        store.dispatch(updateProject(response.json))
      }
    })
  }

  consumer.subscriptions.create({channel: "ProjectChannel", project_uuid: projectID}, {
    connected() {
      // Called when the subscription is ready for use on the server
      console.log('Project Connected')
      setTimeout( () => {
        refreshJobs();
        refreshSnapshots();
        refreshProject();
      }, 500 );
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(message) {
      // Called when there's incoming data on the websocket for this channel
      console.log(`Project Message Received: ${message.id}`)
      // console.log(message.data) // DEBUG

      switch(message.id) {
        case 'jobs_updated':
          store.dispatch(updateJobs(message.data));
          break;
        case 'project_updated':
          store.dispatch(updateProject(message.data));
          break;
        case 'snapshots_updated':
          store.dispatch(updateSnapshots(message.data));
          break;
        default:
          console.warn(`Unknown message ID: ${message.id}`);
          break;
      }
    }
  });


}

export { initializedProkseeServerDispatchEvents }

