import React from 'react';
// import File from './File';
import * as helpers from '../../support/Helpers';
import * as validations from '../../support/Validations';

class JSONFile {

  constructor(file) {
    this._file = file;
  }

  get file() {
    return this._file;
  }

  //////////////////////////////////////////////////////////////////////////
  // Required Delegate Methods
  //////////////////////////////////////////////////////////////////////////

  get fileType() {
    return 'json';
  }

  get metaKeys() {
    return ['keys', 'json'];
  }

  get validationKeys() {
    return ['allowedKeys', 'requiredKeys'];
  }

  processText(text) {
    let json;
    try {
      json = JSON.parse(text);
      this.keys = Object.keys(json);
    } catch (error) {
      console.log('JSON Parse Error:', error);
      this.keys = [];
    }
    this.json = json;
    console.log(json)
  }

  validate(rules={}) {
    const validationErrors = [];
    const { allowedKeys, requiredKeys } = rules;
    const v = validations;
    const details = this.file.details();
    let continueValidation = true;

    if (!details.meta.json) {
      validationErrors.push(`Invalid JSON`);
      continueValidation = false;
    }

    if (continueValidation && requiredKeys) {
      const keys = details.meta.keys;
      const missingKeys = [];
      for (const requiredKey of requiredKeys) {
        if (!v.validateString(requiredKey, keys)) {
          missingKeys.push(requiredKey);
        }
      }
      if (missingKeys.length > 0) {
        validationErrors.push(`Missing Keys: ${missingKeys.join(', ')}`);
      }
    }

    this.file.validationErrors = validationErrors;
    return {ok: (validationErrors.length === 0), errors: validationErrors};
  }

  static detailsRenderer(details={}, rules={}) {
    const v = validations;
    const allKeys = details.meta.keys;
    let keys = [];
    const ignored = [];
    let ignoredKeys;
    if (rules && rules.allowedKeys) {
      for (const key of allKeys) {
        v.validateString(key, rules.allowedKeys) ? keys.push(key) : ignored.push(key);
      }
      if (ignored.length > 0) {
        ignoredKeys = <tr><td>Ignored Columns:</td><td>{ignored.join(', ')}</td></tr>;
      }
    } else {
      keys = allKeys;
    }
    return (
      <table>
        <tbody>
          <tr><td>Keys:</td><td>{keys.join(', ')}</td></tr>
          {ignoredKeys}
        </tbody>
      </table>
    )
  }

}

export default JSONFile;
