import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// TODO: Phase out Modal and Button
import { Modal, Button } from 'antd';
import './Dialog.css';

class Dialog extends React.Component {

  static propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    errors: PropTypes.array,
  }

  static defaultProps = {
    // visible: false,
    visible: true,
    errors: [],
    onClose: () => {},
  }

  constructor(props) {
    super(props);
    this.state = { visible: this.props.visible, errors: props.errors };
    // this.state = { visible: this.props.visible, errors: [] };
  }


  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    this.setState({
      visible: false,
    });
    this.props.onClose({action: 'ok', dialog: this});
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
    this.props.onClose({action: 'cancel', dialog: this});
  };

  showErrors = (errors) => {
    // console.log(errors)
    this.setState({
      // confirmLoading: false,
      errors: errors,
    });
  }

  displayErrors = () => {
    const { errors } = this.state;
    return (errors.length > 0) && (
      <div className='ps-alert ps-alert-danger dialog-error'>
        {errors.map( e => <p key={e}>{e}</p> )}
      </div>
    ) 
  }

  render() {
    const { visible } = this.state;
    const { children } = this.props;
    return (
      <Modal
        className='Dialog'
        visible={visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        {...this.props}
      >
        {this.displayErrors()}
        {children}
      </Modal>
    );
  }
}

export default Dialog;
