// import React from 'react'
// import ReactDOM from 'react-dom'
// import './GenomeProject.css';
// import PropTypes from 'prop-types'
// import FileInput from "../../proksee-viewer/presenters/FileInput";
// import Button from "../../proksee-viewer/presenters/Button";
// import ImageButton from "../../proksee-viewer/presenters/ImageButton";
// import ExternalLink from "../../proksee-viewer/presenters/ExternalLink";
// // import * as helpers from "../proksee-viewer/support/Helpers";

// class GenomeProjectOLD extends React.Component {

//   static propTypes = {
//     active: PropTypes.bool,
//     environment: PropTypes.oneOf(['development', 'production']),
//   }

//   static defaultProps = {
//     active: false,
//     environment:  'production',
//   }

//   constructor(props) {
//     super(props);
//     this.state = {
//       valid: false
//     };
//     this.onValidate = this.onValidate.bind(this);
//   }

//   onValidate(valid) {
//     console.log(valid)
//     this.setState({valid});
//   }

//   static downloadURI(uri, name) {
//     const link = document.createElement("a");
//     link.download = name;
//     link.href = uri;
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   }

//   renderExample(id, name, description, db='nuccore') {
//     return (
//       <div className='example-block'>
//         <a className='example-btn-start' href={`/projects/example?source=genome&id=${id}`}>Start</a>
//         <div className='example-details'>
//           <div className='example-name' title={name}>{name}</div>
//           <div className='example-description'>{description}</div>
//           <div className='example-genbank'>
//             <ImageButton className='example-genbank-download' text='GenBank' size='small' imageName='downloadSmall' title='Download Starting GenBank File' width={80}
//               onClick={() => { GenomeProjectOLD.downloadURI(`/data/genomes/${id}`, `${id}.gb`) }}
//             />
//             <ExternalLink name={id} link={`https://www.ncbi.nlm.nih.gov/${db}/${id}`} className='example-genbank-id' size={10} />
//           </div>
//         </div>
//       </div>
//     );
//   }

//   renderDevelopmentExamples() {
//     if (this.props.environment === 'development') {
//       return (
//         <fieldset className='dev-mode'>
//           <legend>Development Examples</legend>
//           { this.renderExample('MNHM01000001.1', 'Chloroflexi bacterium 13_1_40CM_2_67_6', '133,342 bp (13 contigs)') }
//           { this.renderExample('NC_001823.1', 'Reclinomonas americana mitochondrion', '69,034 bp') }
//           { this.renderExample('M77789.2', 'Cloning Vector pUC19', '2,686 bp') }
//         </fieldset>
//       );
//     }
//   }

//   render() {
//     const { active } = this.props;
//     const { valid } = this.state;

//     // Only retrun field name if active.
//     // This prevent sending the file when inactive.
//     const fieldName = active ? "project[jobs_attributes][0][files_attributes][0]" : '';
//     const source = active ? "project[source]" : '';

//     return (
//       <div className='new-project-page'>
//         <div>Genome Sequence</div>
//         <FileInput
//           sourcesAllowed={['upload', 'ncbi']}
//           fieldName={fieldName}
//           help="Proksee accepts GenBank, EMBL, FASTA, and raw formats. Sequences must be between 1,000 and 10 million bases long."
//           fileType='sequence'
//           // useUppy
//           validationRules={{
//             validMolTypes: 'DNA',
//             validLength: [1000, 10000000],
//             validCount: [1,1000],
//             // Temporary size increase
//             // validLength: [1000, 50000000],
//             // validCount: [1,5000],
//           }}
//           onValidate={this.onValidate}
//         />
//         <input id="source" name={source} type="hidden" value="genome" />
//         <input className='hidden-submit' type='submit' onClick={(e)=> {
//           if (!valid) {e.preventDefault();}
//         }}/>
//         <div className='btn-submit-or-examples'>
//           <Button
//             type='primary'
//             className='btn-submit-project'
//             onClick={() => document.forms['new_project'].submit()}
//             width={150}
//             height={38}
//             disabled={!valid}>
//             Create Map
//           </Button>

//           <fieldset className='or-examples'><legend>OR</legend></fieldset>

//           <div className='example-section'>
//             <div className='run-example'>Start an Example</div>
//             { this.renderExample('NZ_CP007470.1', 'Haemophilus influenzae', '1,846,259 bp') }
//             { this.renderExample('NC_000913.3', 'Escherichia coli str. K-12', '4,641,652 bp') }
//             { this.renderExample('CP021212.1', 'Escherichia coli B171 plasmid', '90,229 bp') }
//             { this.renderExample('GCF_000335355.2', 'Escherichia coli PA2', '5,246,924 bp (109 contigs)', 'assembly') }
//             <div className='example-help'>Click <strong>Start</strong> to create an example genome project using the provided GenBank file. For each example, there is button to download the starting GenBank file as well as a link to view it at NCBI.</div>
//             { this.renderDevelopmentExamples() }
//           </div>

//         </div>
//       </div>
//     )
//   }

// }
// export default GenomeProjectOLD;

