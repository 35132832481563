import { SNAPSHOTS_ADD, SNAPSHOTS_UPDATE, SNAPSHOTS_RESET, SNAPSHOTS_CLEAR_RESET } from '../constants/ActionTypes'

export const addSnapshots = (snapshots) => ({
  type: SNAPSHOTS_ADD,
  payload: {
    snapshots,
  }
});

export const updateSnapshots = (snapshots, attributes) => ({
  type: SNAPSHOTS_UPDATE,
  payload: {
    snapshots,
    attributes,
  }
});

export const resetSnapshots = () => ({
  type: SNAPSHOTS_RESET,
});

export const clearSnapshotsReset = () => ({
  type: SNAPSHOTS_CLEAR_RESET,
});
