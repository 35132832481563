import React from 'react';
import Button from '../../../presenters/Button';
import ButtonGroup from '../../../presenters/ButtonGroup';
import DataElementContainer from '../../../presenters/DataElementContainer';
import { MapStyleSectionLabel, StyleLegendItem, StyleColor, StyleColorOrText, StyleNumber, StyleBoolean, StyleFont } from './MapStyleComponents';
// import * as helpers from '../../../support/Helpers';
import './MapStyleSelection.css';


// mapStyles: MapStyle objects
// selectedStyleKeys: Array of keys of selected styles
// onSelectionChange: Callback function to update selectedStyleKeys
export function MapStyleSelection({ mapStyle, selectedStyleKeys, onSelectionChange }) {
  // console.log(mapStyle)

  const toggleStyle = (keys, selected) => {
    // Ensure keys is always an array
    keys = Array.isArray(keys) ? keys : [keys];
    const selectedSet = new Set(selectedStyleKeys);
    if (selected === undefined) {
      // Toggle each key in the array
      keys.forEach((key) => {
        if (selectedSet.has(key)) {
          selectedSet.delete(key);
        } else {
          selectedSet.add(key);
        }
      });
    } else if (selected) {
      // Add all keys
      keys.forEach((key) => selectedSet.add(key));
    } else {
      // Remove all keys
      keys.forEach((key) => selectedSet.delete(key));
    }
    onSelectionChange(Array.from(selectedSet));
  };

  const selectAll = () => {
    onSelectionChange(mapStyle.allStyleKeys);
  };

  const selectNone = () => {
    onSelectionChange([]);
  };

  return (
    <div className="MapStyleSelection">
      <div className='btns-all-or-none'>
        <ButtonGroup>
          <Button width={60} height={24} onClick={selectAll} disabled={selectedStyleKeys.length === mapStyle.allStyleKeys.length} >
            All
          </Button>
          <Button width={60} height={24} onClick={selectNone} disabled={selectedStyleKeys.length === 0} >
            None
          </Button>
        </ButtonGroup>
      </div>
      <MapStyleSelectionSection label='Legend Items' section='legendItem' mapStyle={mapStyle} selectedStyleKeys={selectedStyleKeys} toggleStyle={toggleStyle} />
      <MapStyleSelectionSection label='General Settings' section='settings' mapStyle={mapStyle} selectedStyleKeys={selectedStyleKeys} toggleStyle={toggleStyle} />
      <MapStyleSelectionSection label='Labels' section='labels' mapStyle={mapStyle} selectedStyleKeys={selectedStyleKeys} toggleStyle={toggleStyle} />
    </div>
  );
}

function MapStyleSelectionSection({ label, section, mapStyle, selectedStyleKeys, toggleStyle }) {
  const mapStyleByKey = mapStyle.mapStyleByKey;
  const sectionKeys = mapStyle.keysForSection(section);
  if (sectionKeys.length === 0) {
    return null;
  }
  const componentByKey = {
    'legendItem': StyleLegendItem,
    'settings-backgroundColor': StyleColor,
    'settings-arrowHeadLength': StyleNumber,
    'settings-showShading': StyleBoolean,
    // 'labels-color': StyleColorOrText,
    'labels-font': StyleFont,
    'labels-onlyDrawFavorites': StyleBoolean,
  }

  return (
      <DataElementContainer closable label={<MapStyleSectionLabel label={label} selectedMapStyles={selectedStyleKeys} mapStyleIDs={sectionKeys} toggleStyle={toggleStyle} />}>
      <table><tbody>
          {sectionKeys.map((key) => {
            const Component = componentByKey[(key.startsWith('legendItem') ? 'legendItem' : key)];
            if (!Component) {
              console.error(`No component found for key: ${key}`);
              return null;
            }
            const name = mapStyle.displayNameForKey(key);
            return (
            <tr key={key} onClick={() => toggleStyle(key)}>
              <td><input type="checkbox" checked={selectedStyleKeys.includes(key)} onChange={() => {}} /></td>
              <td><Component name={name} style={mapStyleByKey[key]} /></td>
            </tr>
          )})}
      </tbody></table>
    </DataElementContainer>
  );
}