// import { clearSnapshotsReset } from '../actions/snapshots';
import { RESET_STATE, SNAPSHOTS_ADD, SNAPSHOTS_UPDATE, SNAPSHOTS_RESET, SNAPSHOTS_CLEAR_RESET } from '../constants/ActionTypes'
// import * as helpers from '../support/Helpers';

const initialState = {
  byID: {},
  ids: [],
  reset: false,
};

export default (state = initialState, { type, payload }) => {
  switch(type) {
    // Remove reset for now, as it empties the snapshots when loading a snapshot
    // case RESET_STATE:      return reset(state, payload);
    case SNAPSHOTS_ADD:          return addSnapshots(state, payload);
    case SNAPSHOTS_UPDATE:       return updateSnapshots(state, payload);
    case SNAPSHOTS_RESET:        return resetSnapshots(state);
    case SNAPSHOTS_CLEAR_RESET:  return clearSnapshotsReset(state);
    default:                     return state;
  }
};

const reset = () => { return (initialState) }

// NOT used yet
// const addsnapshots = (state, { snapshots }) => {
//   const data = {};
//   const ids = [];
//   for (var i = 0, len = snapshots.length; i < len; i++) {
//     const snapshot = snapshots[i];
//     snapshot.toolName = snapshot.tool.name;
//     data[snapshot.id] = snapshot;
//     ids.push(snapshot.id);
//   }

//   return ({
//     byID: {...state.byID, ...data},
//     ids: [...state.ids, ...ids],
//   })
// }

// For now only updateSnapshots is used (for adding and updating)
// This may change when we get more advanced
// SO we also need to account for snapshot deletion
const updateSnapshots = (state, { snapshots, attributes }) => {
  const snapshotData = {};
  const ids = [];
  // console.log('snapshots', snapshots)
  for (var i = 0, len = snapshots.length; i < len; i++) {
    const snapshot = snapshots[i];
    if (state.byID[snapshot.id]) {
      // Update snapshot
      snapshotData[snapshot.id] = {...state.byID[snapshot.id], ...snapshot};
    } else {
      // Add snapshot
      snapshotData[snapshot.id] = snapshot;
      ids.push(snapshot.id);
    }
  }

  // REMOVE SNAPSHOTS NOT PRESENT
  // FIXME: This only works while we are always receiving the complete snapshot list
  const newIDs = snapshots.map( j => j.id );

  return ({
    // byID: {...state.byID, ...snapshotData},
    byID: {...snapshotData}, // Only works because we are receiving all the snapshots with the update
    // ids: state.ids,
    // ids: [...state.ids, ...ids],
    ids: newIDs,
  });
}

const resetSnapshots = (state) => {
  return ({
    byID: {...state.byID},
    ids: [...state.ids],
    reset: true
  });
}

const clearSnapshotsReset = (state) => {
  return ({
    byID: {...state.byID},
    ids: [...state.ids],
    reset: false
  });
}