import { RESET_STATE, JOBS_ADD, JOBS_UPDATE, LOG_UPDATE, FILETREE_UPDATE } from '../constants/ActionTypes'
import Tools from '../models/Tools';
import * as helpers from '../support/Helpers';

const initialState = {
  byID: {},
  ids: [],
};

export default (state = initialState, { type, payload }) => {
  switch(type) {
    // Remove reset for now, as it empties the snapshots when loading a snapshot
    // case RESET_STATE:     return reset(state, payload);
    case JOBS_ADD:        return addJobs(state, payload);
    case JOBS_UPDATE:     return updateJobs(state, payload);
    case LOG_UPDATE:      return updateLog(state, payload);
    case FILETREE_UPDATE: return updateFileTree(state, payload);
    default:              return state;
  }
};

const reset = () => { return (initialState) }

// NOT used yet
const addJobs = (state, { jobs }) => {
  const data = {};
  const ids = [];
  for (var i = 0, len = jobs.length; i < len; i++) {
    const job = jobs[i];
    job.toolName = job.tool.name;
    data[job.id] = job;
    ids.push(job.id);
  }

  return ({
    byID: {...state.byID, ...data},
    ids: [...state.ids, ...ids],
  })
}

// For now only updateJobs is used (for adding and updating)
// This may change when we get more advanced
// SO we also need to account for job deletion
const updateJobs = (state, { jobs, attributes }) => {
  const jobData = {};
  const ids = [];

  for (var i = 0, len = jobs.length; i < len; i++) {
    const job = jobs[i];
    job.toolName = job.tool.name;
    if (state.byID[job.id]) {
      // Update Job
      jobData[job.id] = {...state.byID[job.id], ...job};
    } else {
      // Add Job
      job.log = '';
      job.logOffset = 0;
      jobData[job.id] = job;
      ids.push(job.id);
    }
    const finalized = (job.status === 'complete' || job.status === 'failed');
    jobData[job.id].finalized = finalized;
    // Is the job complete and does the tool have an Add Dialog?
    const tool = Tools.get(job.tool.publicID);
    jobData[job.id].canAddResults = Boolean(job.status === 'complete' && tool.components.DialogAdd);
    // jobData[job.id].canDelete = Boolean(finalized && tool.id !== 'cgview_builder');
    jobData[job.id].canDelete = Boolean(finalized && tool.deletableJobs);

    // console.log(Object.keys(jobData).length);

  }

  // REMOVE JOBS NOT PRESENT
  // FIXME: This only works while we are always receiving the complete job list
  const newIDs = jobs.map( j => j.id );

  return ({
    // byID: {...state.byID, ...jobData},
    byID: {...jobData}, // Only works because we are receiving all the jobs with the update
    // ids: state.ids,
    // ids: [...state.ids, ...ids],
    ids: newIDs,
  });
}

const updateLog = (state, {id, offset, log}) => {

  const jobData = {};
  const job = state.byID[id];
  const finalized = (job.status === 'complete' || job.status === 'failed');
  if (offset !== job.logOffset) {
    jobData[id] = {...state.byID[id], logOffset: offset, log: state.byID[id].log + log, finalized};
    // console.log("UPDATE", offset, state.byID[id].log.length, log.length)
  } else {
    // This will force a rerender and reset the timer to check for log updates
    jobData[id] = {...state.byID[id], finalized};
  }

  return ({
    byID: {...state.byID, ...jobData},
    ids: state.ids,
  });
}

const updateFileTree = (state, {id, fileTree}) => {
  const jobData = {};
  const job = state.byID[id];
  const finalized = (job.status === 'complete' || job.status === 'failed');
  if (finalized) {
    jobData[id] = {...state.byID[id], fileTree: fileTree, finalized};
  }

  return ({
    byID: {...state.byID, ...jobData},
    ids: state.ids,
  });
}


