import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Switch.css'; 

Switch.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  onString: PropTypes.string,
  offString: PropTypes.string,
  value: PropTypes.bool,
  default: PropTypes.bool,
  size:    PropTypes.oneOf(['default', 'small']),
}

Switch.defaultProps = {
  disabled: false,
  onString: 'Yes',
  offString: 'No',
  width: '100%',
  onChange: () => {},
  defaultValue: false,
  size:     'default',
}

function Switch(props) {

  const {
    disabled,
    className,
    onString,
    offString,
    width, // css width: percent, pixels, etc
    value,
    defaultValue,
    onChange,
    size,
  } = props;

  const [isTrue, setIsTrue] = useState( (value === undefined) ? defaultValue : value );

   // Update state when the `value` prop changes
   useEffect(() => {
    if (value !== undefined) {
      setIsTrue(value);
    }
  }, [value]);

  function handleClick() {
    if (disabled) return;
    const newValue = !isTrue;
    setIsTrue(newValue);
    onChange(newValue);
  }

  const switchClass = classNames(
    'Switch', className,
    {small: size === 'small'},
    {disabled}
  );

  const text = isTrue ? onString : offString;

  // console.log('Switch render', value, isTrue);
  return (
    <div className={switchClass}
      // onClick={handleClick}
      onClick={(e) => {e.stopPropagation();handleClick();}}
      style={{width}}
    >
      <input type='checkbox' checked={isTrue} readOnly />
      <span className='slider'>{text}</span>
    </div>
  )
}

export default Switch;


