import React from 'react';
import './DisplayPane.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "../support/react-tabs.sidebar.css";
import DownloadPane from './DownloadPane';
import { ConnectedSnapshotPane } from './SnapshotPane';
// import { ConnectedCaptionPane } from './CaptionPane';
// import { ConnectedLegendPane } from './LegendPane';
// import { SettingsPane } from './SettingsPane';

class DataPane extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tabIndex: props.tabIndex || 0, // Production
      // tabIndex: props.tabIndex || 1, // Tab for current development
    }
  }

  setTabIndex(tabIndex) {
    if (tabIndex !== undefined) {
      this.setState({tabIndex});
    }
  }

  render() {
    const {registerPane} = this.props;
    return (
      <div className='DataPane'>
        <Tabs className='sub-tabs' selectedIndex={this.state.tabIndex} onSelect={ tabIndex => this.setState({ tabIndex }) } forceRenderTabPanel >
          <TabList>
            <Tab>Download</Tab>
            <Tab>Snapshots</Tab>
            {/* <Tab>History</Tab> */}
          </TabList>
          <TabPanel><DownloadPane ref={ref => registerPane('downloads', ref, 0, 'data')} /></TabPanel>
          <TabPanel><ConnectedSnapshotPane ref={ref => registerPane('snapshots', ref, 1, 'data')} /></TabPanel>
          {/* <TabPanel>History</TabPanel> */}
        </Tabs>
      </div>
    );
  }
}

export default DataPane;


