import React from 'react';
import PropTypes from 'prop-types';
import { CGViewContext } from '../app/CGViewContext';
import './AboutTab.css';
import DataElement from '../presenters/DataElement';
import DataElementGroup from '../presenters/DataElementGroup';
import TextElement from '../presenters/TextElement';
import * as helpers from '../support/Helpers';
// import { ConnectedProjectName } from '../containers/ProjectName';
// Connected
import { connect } from 'react-redux';


class AboutTab extends React.Component {

  static propTypes = {
    name:         PropTypes.string,
    id:           PropTypes.string,
    geneticCode:  PropTypes.number,
    projectSize:  PropTypes.number,
    daysLeft:     PropTypes.number,
    createdAt:    PropTypes.string,
    updatedAt:    PropTypes.string,
    contigCount:  PropTypes.number,
    contigs:      PropTypes.array,
    featureCount: PropTypes.number,
    plotCount:    PropTypes.number,
    bookmarkCount: PropTypes.number,
    trackCount:   PropTypes.number,
    legendCount:  PropTypes.number,
    captionCount: PropTypes.number,
    source:       PropTypes.string,
  }

  static defaultProps = {
    featureCount: 0,
    plotCount: 0,
  }

  constructor(props) {
    super(props);
    this.divID = props.divID;
  }

  render() {
    // To Add: Meta data, Stats, ...
    const {
      name,
      id,
      geneticCode,
      projectSize,
      sessionProject,
      dataHasChanged,
      daysLeft,
      createdAt,
      updatedAt,
      contigs,
      contigCount,
      snapshotCount,
      jobCount,
      trackCount,
      legendCount,
      captionCount,
      featureCount,
      plotCount,
      bookmarkCount,
      source,
    } = this.props;

    const cgv = this.context.cgv;
    const sidebar = this.context.sidebarRef;

    const visibleContigs = contigs.filter( c => c.visible );
    const visibleContigCount = visibleContigs.length;
    const seqLength = contigs.reduce( (a, c) => {return a + c.length}, 0 );

    let displayContigTitle = 'Contig Count';
    let displayContigCount = contigs.length;
    let displaySeqLengthTitle = 'Sequence Length';
    let displaySeqLength = `${helpers.commaNumber(seqLength)} bp`;

    if (visibleContigs.length < contigs.length) {
      displayContigTitle = 'Contig Count (Visible)';
      displayContigCount = `${contigs.length} (${visibleContigs.length})`;
      displaySeqLengthTitle = 'Sequence Length (Visible)';
      const visibleSeqLength = visibleContigs.reduce( (a, c) => {return a + c.length}, 0 );
      displaySeqLength = `${displaySeqLength} (${helpers.commaNumber(visibleSeqLength)} bp)`;
    }
    const daysLeftElement = daysLeft && (
      <DataElement label='Days Left (Before Deletion)'>
        <TextElement>{daysLeft}</TextElement>
      </DataElement>
    );

    const dataHasChangedAlert = dataHasChanged && (
      <div className='ps-alert ps-alert-notice'>
        <div>Your map has unsaved changes. Click the <strong>Save Changes</strong> button above to save your map changes to the server.</div>
      </div>
    )

    const sessionProjectAlert = sessionProject && (
      <div className='ps-alert ps-alert-notice'>
        <div>Session projects are projects that have been created with this web browser while not logged in.</div>
        <div><strong>Session projects are deleted 7 days after the Last Save Date.</strong></div>
        <div>The project can be added to your account under <a href='/projects'>My Projects</a></div>
      </div>
    )

    return (
      <div className='AboutTab' ref={this.pane}>
        <div>
          {dataHasChangedAlert}
          {sessionProjectAlert}
          <DataElement label='Name' helpPath='help:project:tabs:about:name'>
            <TextElement>{name}</TextElement>
          </DataElement>
          <DataElement label='Map ID' helpPath='help:project:tabs:about:map_id'>
            <TextElement>{id}</TextElement>
          </DataElement>
          <DataElementGroup>
            <DataElement label='Created' helpPath='help:project:tabs:about:created'>
              <TextElement>{helpers.friendlyDateTime(createdAt)}</TextElement>
            </DataElement>
            <DataElement label='Updated (Last Save Date)' helpPath='help:project:tabs:about:updated'>
              <TextElement>{helpers.friendlyDateTime(updatedAt)}</TextElement>
            </DataElement>
          </DataElementGroup>
          <DataElementGroup>
            <DataElement label='Project Size' helpPath='help:project:tabs:about:project_size'>
              <TextElement>{helpers.formatBytes(projectSize)}</TextElement>
            </DataElement>
            {daysLeftElement}
          </DataElementGroup>
          <DataElementGroup>
            <DataElement label={displaySeqLengthTitle} helpPath='help:project:tabs:about:sequence_length'>
              <TextElement>{displaySeqLength}</TextElement>
            </DataElement>
            <DataElement label={displayContigTitle} helpPath='help:project:tabs:about:contig_count'>
              <TextElement clickable onClick={() => sidebar.selectPane('contigs')} >
                {displayContigCount}
              </TextElement>
            </DataElement>
          </DataElementGroup>
          <DataElementGroup>
            <DataElement label='Feature Count' helpPath='help:project:tabs:about:feature_count'>
              <TextElement clickable onClick={() => sidebar.selectPane('features')} >
                {helpers.commaNumber(featureCount)}
              </TextElement>
            </DataElement>
            <DataElement label='Plot Count' helpPath='help:project:tabs:about:plot_count'>
              <TextElement clickable onClick={() => sidebar.selectPane('plots')} >
                {plotCount}
              </TextElement>
            </DataElement>
            <DataElement label='Bookmark Count' helpPath='help:project:tabs:about:bookmark_count'>
              <TextElement clickable onClick={() => sidebar.selectPane('bookmarks')} >
                {bookmarkCount}
              </TextElement>
            </DataElement>
          </DataElementGroup>
          <DataElementGroup>
            <DataElement label='Track Count' helpPath='help:project:tabs:about:track_count'>
              <TextElement clickable onClick={() => sidebar.selectPane('tracks')} >
                {trackCount}
              </TextElement>
            </DataElement>
            <DataElement label='Legend Count' helpPath='help:project:tabs:about:legend_count'>
              <TextElement clickable onClick={() => sidebar.selectPane('legend')} >
                {legendCount}
              </TextElement>
            </DataElement>
            <DataElement label='Caption Count' helpPath='help:project:tabs:about:caption_count'>
              <TextElement clickable onClick={() => sidebar.selectPane('captions')} >
                {captionCount}
              </TextElement>
            </DataElement>
          </DataElementGroup>
          <DataElementGroup>
            <DataElement label='Job Count' helpPath='help:project:tabs:about:job_count'>
              <TextElement clickable onClick={() => sidebar.selectPane('jobs')} >
                {jobCount}
              </TextElement>
            </DataElement>
            <DataElement label='Snapshot Count' helpPath='help:project:tabs:about:snapshot_count'>
              <TextElement clickable onClick={() => sidebar.selectPane('snapshots')} >
                {snapshotCount}
              </TextElement>
            </DataElement>
          </DataElementGroup>
          <DataElementGroup>
            <DataElement label='Project Source' helpPath='help:project:tabs:about:source'>
              <TextElement>{source}</TextElement>
            </DataElement>
          </DataElementGroup>
          <DataElementGroup>
            <DataElement label='Genetic Code' helpPath='help:project:tabs:about:genetic_code'>
              <TextElement>{geneticCode} - {cgv.codonTables.names()[geneticCode]}</TextElement>
            </DataElement>
          </DataElementGroup>
        </div>

        <br />

        <div id='proksee-footer'>
          <a href='/' className='footer-logo'></a>
          <div className='footer-sections'>
            <div className='footer-section'>
              <div className='footer-section-label muted'>About</div>
              <div className='footer-section-items'>
                <div className='footer-section-item'><a href='/privacy'>Privacy Policy</a></div>
                <div className='footer-section-item-divider muted'>&bull;</div>
                <div className='footer-section-item'><a href='/posts'>News</a></div>
                <div className='footer-section-item-divider muted'>&bull;</div>
                <div className='footer-section-item'><a href='/tools'>Tools</a></div>
              </div>
            </div>
            <div className='footer-section'>
              <div className='footer-section-label muted'>Contact</div>
              <div className='footer-section-items'>
                <div className='footer-section-item'><a href="mailto:info@proksee.ca?subject=Proksee%20Feedback">info@proksee.ca</a></div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

AboutTab.contextType = CGViewContext;

const AboutTabStateToProps = (state) => ({
  name: state.viewer.name,
  id: state.viewer.id,
  geneticCode: state.settings.geneticCode,
  projectSize: state.project.size,
  daysLeft: state.project.daysRemaining,
  sessionProject: state.project.sessionProject,
  dataHasChanged: state.viewer.dataHasChanged,
  createdAt: state.project.createdAt,
  updatedAt: state.project.updatedAt,
  featureCount: state.features.ids.length,
  plotCount: state.plots.ids.length,
  bookmarkCount: state.bookmarks.ids.length,
  snapshotCount: state.snapshots.ids.length,
  jobCount: state.jobs.ids.length,
  trackCount: state.tracks.ids.length,
  legendCount: state.legendItems.ids.length,
  captionCount: state.captions.ids.length,
  contigCount: state.contigs.ids.length,
  contigs: state.contigs.ids.map( id => state.contigs.byID[id] ),
  source: state.project.source,
});

const ConnectedAboutTab = connect(AboutTabStateToProps)(AboutTab);

// export default AboutTab;
export { AboutTab, ConnectedAboutTab };


