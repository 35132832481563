import React, { useState, useEffect } from 'react';
import DataElementContainer from '../../../presenters/DataElementContainer';
import FileInput from "../../../presenters/FileInput";
import Button from '../../../presenters/Button';
import MapStyle from './MapStyle';
import { MapStyleSelection } from './MapStyleSelection';
import Toast from '../../../presenters/Toast';
// import * as helpers from '../../../support/Helpers';
import './MapStyleImport.css';
import './MapStyleDialog.css';

export default function MapStyleImport({ cgv }) {

  const [selectedMapStyles, setSelectedMapStyles] = useState([]);
  const [importJSON, setImportJSON] = useState(undefined);
  const [myMapStyle, setMyMapStyle] = useState(new MapStyle({json: {}}));

  useEffect(() => {
    if (importJSON) {
      console.log('Importing:', importJSON);
      setMyMapStyle(new MapStyle({json: importJSON}));
    }
  }, [importJSON]);

  function onHandleFileChange(pfile) {
    const json = pfile?.delegate?.json;
    setImportJSON(json);
  }

  function importMapStyle() {
    if (!cgv) {
      console.error('No CGView provided');
      return;
    }
    myMapStyle.applyStylesForKeys({keys: selectedMapStyles, cgv});
    console.log("Updated map style");
    Toast.create('Styles have been applied to the map', 2000);
  }

  let mainImportContent;
  if (importJSON) {
    mainImportContent = (
      <div style={{marginTop: 20}}>
        <MapStyleSelection mapStyle={myMapStyle} selectedStyleKeys={selectedMapStyles} onSelectionChange={setSelectedMapStyles} />
        <DataElementContainer closable  closed label='Map Style JSON'>
          <pre className='map-style-json'>{JSON.stringify(importJSON, null, 2)}</pre>
        </DataElementContainer>
        <Button className='btn-import' onClick={importMapStyle} width="100%" height={40} disabled={!cgv || !selectedMapStyles.length }>
          Apply Selected ({selectedMapStyles.length}/{myMapStyle.allStyleKeys.length}) to the Current Map
        </Button>
      </div>
    );
  }

  return (
    <div className="MapStyleImport">
      <div className='dialog-header'>
        Upload a map styles file and select styles to import
      </div>
      <FileInput
        sourcesAllowed={['upload']}
        uploadPlaceHolder="Upload Map Styles JSON..."
        fileType='json'
        showDetails={false}
        validationRules={{
          // requiredKeys: ['legendItems'],
        }}
        onChange={onHandleFileChange}
      />
      { mainImportContent }
    </div>
  );
};

