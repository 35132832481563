import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './FileInput.css';
import '../support/CommonStyles.css';
import Button from './Button';
import ButtonGroup from './ButtonGroup';
import ImageButton from "./ImageButton";
import TextInput from './TextInput';
import ProkseeFile from '../models/ProkseeFile';
import HelpElement from './HelpElement';
import * as helpers from '../support/Helpers';

import Uppy from '@uppy/core';
import UppyXHRUpload from '@uppy/xhr-upload';
// import UppyFileInput from '@uppy/file-input'
// import UppyStatusBar from '@uppy/status-bar'
// import { FileInput as UppyFileInput, StatusBar as UppyStatusBar } from '@uppy/react'
import UppyStatusBar from '@uppy/react/lib/StatusBar'; // This reducing js file size
import '@uppy/core/dist/style.css';
import '@uppy/file-input/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import { mode } from 'd3';
import settings from '../reducers/settings';

class FileInput extends React.Component {

  static propTypes = {
    className:      PropTypes.string,
    defaultSource:    PropTypes.oneOf(['upload', 'ncbi', 'myData']),
    sourcesAllowed:   PropTypes.array,
    // TODO: change to fieldIDRoot
    // - we will add attachment for file and accession for ncbi (these are hardcoded to match DataFile.rb for now)
    // - this allows submitting the file data via html instead of JavaScript
    fieldName:      PropTypes.string,
    fileType:       PropTypes.oneOf(['sequence', 'text', 'csv', 'cgview', 'fastq', 'parse', 'feature', 'json']),
    help:           PropTypes.string,
    helpMore:       PropTypes.node,
    validationRules: PropTypes.object, // Passed to specific file handler
    uploadPlaceHolder: PropTypes.string,
    onValidate:     PropTypes.func,
    onChange:       PropTypes.func,
    useUppy:        PropTypes.bool,
    useSettings:    PropTypes.bool,
    onChangeSettings: PropTypes.func,
    showDetails:    PropTypes.bool,

    // disabled: PropTypes.func,
  }

  static defaultProps = {
    defaultSource: 'upload',
    sourcesAllowed: ['upload', 'ncbi', 'myData'],
    fieldName: '',
    help: '',
    onValidate: () => {},
    onChange: () => {},
    uploadPlaceHolder: 'Upload file...',
    useUppy: false,
    useSettings: false,
    onChangeSettings: () => {},
    showDetails: true,
    // disabled: false,
  }

  constructor(props) {
    super(props);
    const settings = ProkseeFile.fileTypeMap[props.fileType]?.initialSettings || {};
    settings.settingsHaveChanged = false;
    this.state = {
      source: props.defaultSource,
      pfiles: {}, // keys: upload, ncbi
      ncbiAccession: '',
      accessionTyped: false,
      uppyFileData: '',
      processing: false,
      showSettings: false,
      // Settings for a file can be anything that is needed to parse the file
      // The only common setting is 'settingsHaveChanged'
      // - This is used to determine if the file needs to be re-parsed
      // - When settings have changed we no longer show the file details or validation errors
      // - This is set to false when a new file is uploaded (as uploading the file will use the new settings)
      // - The Reparse button is disabled unless settingsHaveChanged is true
      // - If an onChangeSettings function is provided, it will be called with the attribute and value
      //   - And with settingsHaveChanged set to true
      settings,
    }
    this.fileInputRef = React.createRef();
    this.inputForSource = this.inputForSource.bind(this);
    this.onUploadChange = this.onUploadChange.bind(this);
    this.onNCBIChange = this.onNCBIChange.bind(this);
    this.onCheckNCBI = this.onCheckNCBI.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.validate = this.validate.bind(this);
    this.renderValidationErrors = this.renderValidationErrors.bind(this);
    this.renderSettings = this.renderSettings.bind(this);
    this.onChangeSettings = this.onChangeSettings.bind(this);

    if (props.useUppy) {
      this.initializeUppy();
    }
  }

  initializeUppy() {
    this.uppy = new Uppy({
      autoProceed: true,
      restrictions: {
        maxNumberOfFiles: 1,
      },
      // Return 
      // - modifiedFile: new file object
      // - true to keep the file
      // - false to remove the file
      // onBeforeFileAdded: (currentFile, files) => {
      //   console.log('BEFORE ADD')
      //   console.log(currentFile)
      // },
      // onBeforeUpload: (files) => {
      //   console.log('BEFORE UPLOAD')
      //   console.log(files)
      // }
    });
    this.uppy.on('upload-success', (file, response) => {
      var uploadedFileData = JSON.stringify(response.body)
      this.setState({uppyFileData: uploadedFileData});
      // console.log(uploadedFileData)
      this.validate();
    })
    // this.uppy.on('file-added', (file) => {
    //   console.log('ADDED')
    //   console.log(file)
    // })
    this.uppy.use(UppyXHRUpload, {
      endpoint: '/upload',
    })
  }

  componentWillUnmount() {
    if (this.uppy) {
      this.uppy.close();
    }
  }

  onChangeSource(source) {
    this.setState({ source }, this.validate);
    const { pfiles } = this.state;
    const pfile = pfiles[source];
    this.onChange(pfile);
  }

  sourceButtons() {
    const { sourcesAllowed } = this.props;
    const { source } = this.state;
    const sourceMap = {
      upload: 'Upload',
      ncbi:   'NCBI',
      myData: 'My Data',
    }

    const buttons = sourcesAllowed.map( (m) => (
      <Button width={55} key={m}
       active={source === m}
       onClick={ () => this.onChangeSource(m)}>
       {sourceMap[m]}
      </Button>
    ));
    return ( <ButtonGroup>{buttons}</ButtonGroup> );
  }

  updatePFiles(newPFiles) { 
    this.setState((state) => {
      return {
        pfiles: {
          ...state.pfiles,
          ...newPFiles,
        }
      };
    // Only call validate here when not using Uppy. Uppy will call validate on it's own.
    // But we still need to call validate if an accession is used.
    }, (this.props.useUppy && this.state.source == 'upload') ? () => {} : this.validate);
  }

  validate() {
    const { onValidate, useUppy } = this.props;
    const { pfiles, source, settings } = this.state;
    const pfile = pfiles[source];
    const validationErrors = pfile && pfile.validationErrors || [];
    // console.log(pfile)
    console.log('settingsHaveChagned', settings.settingsHaveChanged)
    if (!pfile || validationErrors.length > 0) {
      // useUppy && this.uppy.reset()
      (useUppy && source == 'upload') && this.uppy.reset();
      onValidate(false);
    } else if (source == 'upload' && settings.settingsHaveChanged) {
      // Could be combined with above?
      (useUppy && source == 'upload') && this.uppy.reset();
      onValidate(false);
    } else {
      console.log('VALIDATE - true')
      onValidate(true);
    }
  }

  // This can be used by delegates to open the settings panel
  toggleSettings() {
    this.setState( prevState => ({showSettings: !prevState.showSettings}));
  }

  onChange(pfile) {
    const { onChange } = this.props;
    onChange(pfile);
  }


  onUploadChange(e) {
    const { fileType, validationRules, useUppy } = this.props;
    const { settings } = this.state;

    // const fileList = e.target.files;
    const file = e.target.files[0];
    const stateFile = this.state.pfiles?.upload?.fileValue;

    let updatedSettings = settings;
    if (!(stateFile && stateFile == file)) {
      updatedSettings = { ...settings, ...ProkseeFile.fileTypeMap[fileType]?.fileChangedSettings };
    }
    this.onChangeSettings({attribute: 'settingsHaveChanged', value: false});
    updatedSettings.settingsHaveChanged = false;
    // console.log('UPDATED SETTINGS', updatedSettings);
    const pfile = new ProkseeFile({fileType, fileValue: file, validationRules, settings: updatedSettings, toggleSettings: () => this.toggleSettings()});

    setTimeout(() => {
      this.setState({
        processing: true,
        settings: updatedSettings,
      });

      pfile.parse()
      .then(f => {
        // console.log(pfile)
        // console.log(pfile.details())
        if (useUppy) {
          // console.log('USEUPPY - ADD UPPY FILE')
          if (pfile.hasModifiedFile) {
            this.addUppyFile(pfile.modifiedFile);
          } else {
            this.addUppyFile(file);
          }
        }
        this.updatePFiles({ upload: pfile });
        this.onChange(pfile);

        this.setState({ processing: false });
      });
    }, 1);
  }

  onNCBIChange(accession) {
    // Remove trailing text (after and incluing first whitespace)
    const adjustedAccession = accession.replace(/\s.*/, '');
    this.setState({
      ncbiAccession: adjustedAccession,
      accessionTyped: true,
    });
  }

  onCheckNCBI(force=false) {
    const { fileType, validationRules } = this.props;
    const { ncbiAccession, accessionTyped } = this.state;
    this.setState({ accessionTyped: false });
    const accession = ncbiAccession.trim();
    if (accessionTyped || force) {
      // console.log(`"${accession}"`);
      if (accession === "") {
        this.updatePFiles({ ncbi: undefined });
        // TODO: we may need to call onChange with an empty file here
      } else {
        const pfile = new ProkseeFile({fileType, fileValue: accession, validationRules});
        pfile.parse()
        .then(f => {
          this.updatePFiles({ ncbi: pfile });
          // console.log(pfile.details())
          this.onChange(pfile);
        });
      }
    }
  }

  onMouseMove() {
    if (this.state.accessionTyped) {
      this.onCheckNCBI();
    }
  }

  // Creates a name attribute using the base provided as a prop
  // This is specific to the Proksee DataFile model which has 2 key attributes:
  // - attachment: for file uploads
  // - external_id: for ncbi files
  // To prevent both being sent, the field name will only be provided for the active source
  fieldName(source) {
    const {fieldName} = this.props;
    const attributeMap = {upload: 'attachment', ncbi: 'external_id'};
    // console.log(this.state.source)
    // console.log( (source === this.state.source) ? `${fieldName}[${attributeMap[source]}]` : '');
    if (fieldName === '') {
      return '';
    }
    return (source === this.state.source) ? `${fieldName}[${attributeMap[source]}]` : '';
  }

  uploadInput() {
    const { fieldName, uploadPlaceHolder } = this.props
    const { pfiles } = this.state
    const name = pfiles.upload && pfiles.upload.fileName || uploadPlaceHolder;
    return (
      <div className='my-file'>
        <span className='my-file-control' onClick={ () => { this.fileInputRef.current.click() }} >{name}</span>
      </div>
    )
  }

  ncbiInput() {
    return (
      <ButtonGroup className='my-accession'>
        <TextInput
          autoFocus={true}
          placeholder='Enter a GenBank Accession (e.g. NC_000913)'
          value={this.state.ncbiAccession}
          onChange={this.onNCBIChange}
          onBlur={this.onCheckNCBI}
        />
        <Button onClick={ () => this.onCheckNCBI(true) }>Check</Button>
      </ButtonGroup>
    );
  }

  inputForSource() {
    const { source } = this.state;
    return this[`${source}Input`]();
  }

  helpText() {
    const { help, helpMore } = this.props;
    return <HelpElement text={help} moreText={helpMore} />
  }

  renderProcessing() {
    const contents =  <div><span className='spinner'></span> <span>Processing file...</span></div>;
    return <div className='file-details open'>{contents}</div>;
  }

  renderFileDetails() {
    const { pfiles, source, settings } = this.state;
    const { validationRules, useUppy } = this.props;
    const pfile = pfiles[source];
    // TODO: show Uppy file data here
    if (pfile && (!settings?.settingsHaveChanged || source == 'ncbi')) {
      const contents = pfile.fileError ? <div>ERROR: {pfile.fileError}</div> : <div>{ProkseeFile.detailsRenderer(pfile.details(), validationRules)}</div>;
      return <div className='file-details open'>{contents}</div>;
    } else {
      return <div className='file-details closed' />;
    }
  }

  renderValidationErrors() {
    const { pfiles, source, settings } = this.state;
    const pfile = pfiles[source];
    const validationErrors = pfile && pfile.validationErrors || [];
    if (validationErrors.length > 0 && (!settings?.settingsHaveChanged || source == 'ncbi')) {
      const errors = validationErrors.map( e => <div key={e}>{e}</div> );
      return <div className='file-errors open'>{errors}</div>;
    } else {
      return <div className='file-errors closed' />;
    }
  }

  renderSettingsButton() {
    const { useSettings, fileType } = this.props;
    const { showSettings, settings } = this.state;
    if (useSettings) {
      const notDefault = ProkseeFile.fileTypeMap[fileType].settingsAreNotDefault(settings)
      return (
        <div className='btn-settings-container'>
          {notDefault && <div className='btn-settings-badge'></div>}
          <ImageButton
            className='btn-settings'
            active={showSettings}
            onClick={() => {
              this.setState( prevState => ({showSettings: !prevState.showSettings}));
            }}
            imageName='settings'
            title='File Settings'
          />
        </div>
      );
    }
  }

  onChangeSettings({attribute, value}, callback) {
    const { onChangeSettings, onValidate } = this.props;
    const { source, pfiles } = this.state;
    // If an onChangeSettings function is provided, set its settingsHaveChanged attribute to true
    onChangeSettings && onChangeSettings({attribute: 'settingsHaveChanged', value: true});
    // If an onChangeSettings function is provided, we will call it with the attribute and value
    onChangeSettings && onChangeSettings({attribute, value});
    // Reset Uppy and invalidate (if upload)
    this.uppy?.reset();
    (source == 'upload') && onValidate(false);
    // Save settings to DSM if available
    // NEED BETTER WAY
    const settings = {...this.state.settings, [attribute]: value};
    const pfile = pfiles[source];
    pfile && pfile.saveSettings(settings)
    // console.log('ON CHANGE SETTINGS', attribute, value)
    this.setState((state) => ({
      settings: {
        ...state.settings,
        settingsHaveChanged: true,
        [attribute]: value,
      }
    }), callback);
  }

  renderSettings() {
    const { useSettings, fileType } = this.props;
    const { pfiles, showSettings, settings, source } = this.state;
    const pfile = pfiles[source];
    const details = pfile?.details() || {};
    const fileText = pfile?.text || '';
    // console.log('------------------------------------------')
    // console.log(details)
    // console.log('------------------------------------------')

    if (useSettings && showSettings) {
      return (
        <div className='file-settings open'>
          <div className='file-settings-header'
            onClick={() => {
              this.setState( prevState => ({showSettings: !prevState.showSettings}));
            }}
          >Import Settings</div>
          <div>{ProkseeFile.fileTypeMap[fileType].settingsRenderer(settings, this.onChangeSettings, details, fileText, source)}</div>
          { source === 'ncbi' && <input type='hidden' name={`${this.props.fieldName}[settings]`} value={JSON.stringify(settings)} /> }
        </div>
      )
    } else {
      return (
        <div className='file-settings closed'>
          { source === 'ncbi' && <input type='hidden' name={`${this.props.fieldName}[settings]`} value={JSON.stringify(settings)} /> }
        </div>
      );
    }
  }

  // Temporary way of mapping fileSource to DataFile.rb source.
  // fileSource will eventually be synced to DataFile.rb source
  dataFileSourceTemp() {
    const { source } = this.state;
    const sourceMap = {
      'upload': 'upload',
      'myData': 'picker', // Not used yet
      'ncbi': 'external',
    }
    return sourceMap[source];
  }

  externalDB() {
    const { source, ncbiAccession } = this.state;
    if (source === 'ncbi') {
      return <input type='hidden' name={`${this.props.fieldName}[external_db]`} value={'ncbi'} />
    }
  }

  addUppyFile(file) {
    const { onValidate } = this.props;
    try {
      // Reset uppy and validation when adding a file
      console.log('FILE+++++++++++++++++++++:', file)
      this.uppy.reset();
      onValidate(false);
      this.uppy.addFile({
        source: 'file input',
        name: file.name,
        type: file.type,
        data: file,
      })
    } catch (err) {
      if (err.isRestriction) {
        // handle restrictions
        console.log('Restriction error:', err)
      } else {
        // handle other errors
        console.error(err)
      }
    }
  }

  renderUppy() {
    const { uppyFileData, processing } = this.state;
    const uppyStatusBar = processing ? "" : (
        <UppyStatusBar
          uppy={this.uppy}
          hideUploadButton
          hideAfterFinish={false}
          showProgressDetails
          locale={{strings: {complete: 'Upload Complete'}}}
        />
    );
    return (
      <div>
        { uppyStatusBar }
        <input type='hidden' name={this.fieldName('upload')} value={uppyFileData} />
      </div>
    );
  }
  
  renderInputForFile() {
    const { useUppy } = this.props;
    const { source } = this.state;
    const inputName = useUppy ? '' : this.fieldName('upload');
    return (
      <div>
        <input type='file'
          tabIndex="-1"
          className='my-file-input'
          ref={this.fileInputRef}
          onChange={this.onUploadChange}
          name={inputName}
        />
        { useUppy && source == 'upload' && this.renderUppy() }
      </div>
    );
  }

  static renderReparseButton(enabled = false) {
    return (
      <Button
        className='btn-reparse'
        height={32}
        width='94px'
        disabled={ !enabled }
        onClick={() => {
          ProkseeFile.forceFileReload();
        }}
        title='Reparse current file with new settings'
      >{enabled ? 'Reparse File with New Settings' : 'Reparse File'}</Button>
    );
  }
  static statusFor(...args) {
    if (args.includes('failed')) {
      return '🛑 errors';
    } else if (args.includes('warnings')) {
      return '⚠️ warnings';
    }
    return;
  }
  // TODO: this needs an "id" property that should also be used in renderLog.
  static renderLogButton(options = {}) {
    // status appears right before the button and can be used to show the status of the parse (ie warnging, error)
    const statusMessage = options.statusMessage;
    // FIXME: active is not in sync with the open/closed log. Need to store state somewhere
    const active = options.active;

    function toggleLog() {
      document.querySelector('.parse-log').classList.toggle('hidden');
    }

    return (
      <div className='parse-log-status'>
        <div className='parse-status'>{statusMessage}</div>
        <ImageButton
          text='Log' imageName='log' width={60}
          onClick={() => toggleLog()}
          active={active}
        />
      </div>
    )
  }
  // TODO: this needs an "id" property that should also be used in renderLogButton
  static renderLog(options = {}) {
    const title = options.title || 'LOG';
    const text = options.text || '';
    const open = (options.open === undefined) ? false : options.open;
    const logClass = open ? 'parse-section parse-log' : 'parse-section hidden parse-log';
    return (
      <div className={logClass}>
        <div className='parse-section-header'>{title}</div>
        <div className='parse-section-contents scroll-skinny'>{text}</div>
      </div>
    );
  }


  render() {
    const { className, showDetails } = this.props;
    const { source, ncbiAccession } = this.state;
    const klass = classNames('FileInput', className);

    return (
      <div className={klass} onMouseMove={this.onMouseMove} >
        <div className='input-controls'>
          { this.sourceButtons() }
          { this.inputForSource() }
          { this.renderSettingsButton() }
        </div>
        { this.helpText() }

        { this.renderSettings() }

        { this.state.processing || this.renderValidationErrors() }
        { this.state.processing || (showDetails && this.renderFileDetails()) }
        { this.state.processing && this.renderProcessing() }

        { this.renderInputForFile() }

        <input type='hidden' name={`${this.fieldName('ncbi')}`} value={ncbiAccession} />
        <input type='hidden' name={`${this.props.fieldName}[source]`} value={this.dataFileSourceTemp()} />
        { this.externalDB() }
      </div>
    );
  }
}

export default FileInput;

