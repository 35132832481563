import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CGViewContext } from '../app/CGViewContext';
import './ListPane.css';
import './LegendPane.css';
import VirtualList from '../presenters/VirtualList';
import { ListItem } from '../presenters/ListItem';
import DataElement from '../presenters/DataElement';
import DataElementGroup from '../presenters/DataElementGroup';
import TextEditable from '../presenters/TextEditable';
import TextElement from '../presenters/TextElement';
import ImageButton from '../presenters/ImageButton';
import Font from '../presenters/Font';
import Color from '../presenters/Color';
import Anchor from '../presenters/Anchor';
import Position from '../presenters/Position';
import ButtonGroup from '../presenters/ButtonGroup';
import Slider from '../presenters/Slider';
import VisibilityButton from '../presenters/VisibilityButton';
import LegendDeleteDialog from './dialogs/LegendDeleteDialog';
import * as helpers from '../support/Helpers';
import iconAlignLeft from '../images/icon-align-left.png';
import iconAlignRight from '../images/icon-align-right.png';
import { Color as CGColor } from '../../CGView.js';
import '../support/CommonStyles.css';
// Connected
import { connect } from 'react-redux';
// import { map } from 'd3';

class LegendPane extends React.Component {

  static propTypes = {
    legendItems: PropTypes.shape({
      ids:  PropTypes.array,
      byID: PropTypes.object,
    }).isRequired,
    legend: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      legendInfoOpen: false,
      deleteID: undefined,
    }
    // Legend Related
    this.onLegendChange = this.onLegendChange.bind(this);
    this.legendInfoClicked = this.legendInfoClicked.bind(this);
    this.legendMainRenderer = this.legendMainRenderer.bind(this);
    this.legendInfoRenderer = this.legendInfoRenderer.bind(this);
    // LegendItem Related
    this.listItemID = this.listItemID.bind(this);
    this.listItemMainRenderer = this.listItemMainRenderer.bind(this);
    this.listItemInfoRenderer = this.listItemInfoRenderer.bind(this);
    this.onRowMouseOver = this.onRowMouseOver.bind(this);
    this.onRowMouseOut = this.onRowMouseOut.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.setListRef = this.setListRef.bind(this);
  }

  legendInfoClicked() {
    this.setState( (state) => ({ ...state, legendInfoOpen: !state.legendInfoOpen }));
  }

  onClickMoveTo() {
    const { cgv, tabsRef: tabs } = this.context;
    tabs.setTabByID('map');
    cgv.legend.moveTo(1000);
  }

  legendMainRenderer() {
    const legendData = this.props.legend;
    const titleClass = classNames('list-item-title legend-title', {fade: !legendData.visible});
    const moveButton = (legendData.on === 'map') ?
      <ImageButton
        onClick={ () => this.onClickMoveTo() }
        imageName='goto'
        title='Move To'
      / >
      : '';
    return (
      <div className='list-item-content'>
        <div className='legend-title-spacer'></div>
        <div className={titleClass}>
          Legend
        </div>
        <div className='list-item-options'>
          <ButtonGroup>
            {moveButton}
            <VisibilityButton
              visible={legendData.visible}
              onClick={ () => this.onLegendChange({value: !legendData.visible, attribute: 'visible'}) } />
          </ButtonGroup>
        </div>
      </div>
    );
  }

  sortLegendItemsByTrack() {
    const cgv = this.context.cgv;
    // Track positions:'inside', 'outside', 'both'
    // Go through tracks and add to beginning of array if position is'inside' otherwise add to the end
    const tracks = [];
    for (const track of cgv.tracks()) {
      if (track.position === 'inside') {
        tracks.unshift(track);
      } else {
        tracks.push(track);
      }
    }
    tracks.reverse();

    // For each track, go through every feature/plot and add legend item if not already in legend array
    const legendItems = [];
    for (const track of tracks) {
      if (track.dataType === 'feature') {
        for (const feature of track.features()) {
          legendItems.push(feature.legendItem);
        }
      } else if (track.dataType === 'plot') {
        legendItems.push(track.plot.legendPositive);
        legendItems.push(track.plot.legendNegative);
      }
    }
    const uniqueLegendItems = [...new Set(legendItems)];
    // console.log(uniqueLegendItems.map(legendItem => legendItem.name));
    // Move legend items to new positions
    for (let i = 0; i < uniqueLegendItems.length; i++) {
      const legendItem = uniqueLegendItems[i];
      legendItem.move(i);
    }
  }

  legendInfoRenderer() {
    //default font, alignment
    //default color, background color
    const legend = this.context.cgv.legend;
    const position = legend.position;
    const anchor = legend.anchor;

    const userRole = this.context.userRole;
    const btnSortLegendItemsByTrack = (userRole === 'admin') && (
      <div>
        <ImageButton
          onClick={ () => this.sortLegendItemsByTrack() }
          imageName='refresh'
          width={200}
          text='Sort by Track (Admin Only)'
          title='Sort by Track'
        / >
      </div>
    );

    return (
      <div>
        <Position
          position={position}
          onChange={(value) => this.onLegendChange({value, attribute: 'position'})}
          onRelativeToChange={(value) => this.onLegendChange({value, attribute: 'on'})}
        />
        <Anchor
          disabled={legend.on === 'canvas'}
          auto={anchor.auto}
          xPercent={anchor.xPercent}
          yPercent={anchor.yPercent}
          name={anchor.name}
          onChange={(value) => this.onLegendChange({value, attribute: 'anchor'})}
        />
        <DataElement label='Default Font' helpPath='help:sidebar:display:legend:font'>
          <Font fontString={legend.defaultFont.string}
            onChange={(value) => this.onLegendChange({value, attribute: 'defaultFont'})}
          >
            <ButtonGroup separated={true}>
              <ImageButton
                title='Align Left'
                image={iconAlignLeft}
                active={legend.textAlignment === 'left'}
                onClick={() => this.onLegendChange({value: 'left', attribute: 'textAlignment'})}
              / >
              <ImageButton
                title='Align Right'
                image={iconAlignRight}
                active={legend.textAlignment === 'right'}
                onClick={() => this.onLegendChange({value: 'right', attribute: 'textAlignment'})}
              / >
            </ButtonGroup>
          </Font>
        </DataElement>
        <DataElementGroup>
          <DataElement label='Default Font Color' helpPath='help:sidebar:display:legend:color'>
            <Color
              colorString={legend.defaultFontColor.rgbaString}
              onChange={(value) => this.onLegendChange({value, attribute: 'defaultFontColor'})}
            / >
          </DataElement>
          <DataElement label='Background Color' helpPath='help:sidebar:display:legend:background_color'>
            <Color
              colorString={legend.backgroundColor.rgbaString}
              onChange={(value) => this.onLegendChange({value, attribute: 'backgroundColor'})}
            / >
          </DataElement>
        </DataElementGroup>
        <DataElement label='Default Minimum Arc Length' helpPath='help:sidebar:display:legend:min_arc_length'>
          <Slider min={0} max={2} step={0.1} value={legend.defaultMinArcLength}
            onChange={(value) => this.onLegendChange({value, attribute: 'defaultMinArcLength', redraw: true})} />
        </DataElement>
        {btnSortLegendItemsByTrack}
      </div>
    );
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    const cgv = this.context.cgv;
    cgv.legend.moveItem(oldIndex, newIndex);
  };

  listItemID(index) {
    const legendItems = helpers.itemForIndex(this.props.legendItems, index);
    return legendItems.cgvID
  }

  onClickVisibilityToggle(index) {
    const legendItem = helpers.itemForIndex(this.props.legendItems, index);
    const visible = !legendItem.visible;
    this.onLegendItemChange({cgvID: legendItem.cgvID, value: visible, attribute: 'visible'})
    this.listRef.recomputeRowHeights(index);
  }

  // Scrolls to the item with the id and opens the info
  openInfoFor(id) {
    const index = this.props.legendItems.ids.indexOf(id);
    this.listRef.scrollToRow(index);
    this.listRef.openInfoFor(id);
    this.listRef.recomputeRowHeights(index);
  }

  listItemMainRenderer(index) {
    const legendItem = helpers.itemForIndex(this.props.legendItems, index);
    const titleClass = classNames('list-item-title', {fade: !legendItem.visible});
    return (
      <div className='list-item-content'>
        <div className={titleClass}>
          {legendItem.name}
        </div>
        <div className='list-item-options'>
          <ButtonGroup>
            <VisibilityButton visible={legendItem.visible} onClick={ () => this.onClickVisibilityToggle(index) } />
          </ButtonGroup>
        </div>
      </div>
    );
  }

  onLegendChange({attribute, value, redraw=false}) {
    const cgv = this.context.cgv;
    cgv.legend.update({[attribute]: value});
    redraw && cgv.draw();
  }

  onLegendItemChange({cgvID, attribute, value, redraw=false, recomputeRowHeight=false}) {
    const cgv = this.context.cgv;
    const legendItem = cgv.objects(cgvID);
    cgv.legend.updateItems(legendItem, {[attribute]: value});
    if (recomputeRowHeight) {
      this.listRef.recomputeRowHeights(0);
    }
    redraw && cgv.draw();
  }

  listItemInfoRenderer(index) {
    const legendItemData = helpers.itemForIndex(this.props.legendItems, index);
    const cgvID = legendItemData.cgvID;
    const legendItem = this.context.cgv.objects(cgvID);

    return (
      <div>
        <DataElement label='Name' helpPath='help:sidebar:display:legend:items:name'>
          <TextEditable value={legendItemData.name} onChange={ (value) => this.onLegendItemChange({cgvID, value, attribute: 'name'})}/>
        </DataElement>
        <DataElementGroup>
          <DataElement label= 'Color' helpPath='help:sidebar:display:legend:items:color'>
            <Color
              colorString={legendItemData.swatchColor}
              onChange={(value) => this.onLegendItemChange({cgvID, value, attribute: 'swatchColor', redraw: true})}
            / >
          </DataElement>
          <DataElement label= 'Decoration' helpPath='help:sidebar:display:legend:items:decoration'>
            <ButtonGroup separated={true}>
              <ImageButton
                title='Arrow'
                imageName='decorationArrow'
                active={legendItemData.decoration === 'arrow'}
                onClick={() => this.onLegendItemChange({cgvID, value: 'arrow', attribute: 'decoration', redraw: true})}
              / >
              <ImageButton
                title='Arc'
                imageName='decorationArc'
                active={legendItemData.decoration === 'arc'}
                onClick={() => this.onLegendItemChange({cgvID, value: 'arc', attribute: 'decoration', redraw: true})}
              / >
              <ImageButton
                title='Score'
                imageName='decorationScore'
                active={legendItemData.decoration === 'score'}
                onClick={() => this.onLegendItemChange({cgvID, value: 'score', attribute: 'decoration', redraw: true})}
              / >
              <ImageButton
                title='None'
                imageName='decorationNone'
                active={legendItemData.decoration === 'none'}
                onClick={() => this.onLegendItemChange({cgvID, value: 'none', attribute: 'decoration', redraw: true})}
              / >
            </ButtonGroup>
          </DataElement>
        </DataElementGroup>
        <DataElement label='Font' helpPath='help:sidebar:display:legend:items:font'>
          <Font
            fontString={legendItemData.font}
            allowDefault={true}
            isDefault={legendItem.usingDefaultFont}
            onDefaultClick={() => this.onLegendItemChange({cgvID, value: undefined, attribute: 'font'})}
            onChange={(value) => this.onLegendItemChange({cgvID, value, attribute: 'font'})}
          />
        </DataElement>
        <DataElement label='Font Color' helpPath='help:sidebar:display:legend:items:font_color'>
          <Color
            colorString={legendItemData.fontColor}
            allowDefault={true}
            isDefault={legendItem.usingDefaultFontColor}
            onDefaultClick={() => this.onLegendItemChange({cgvID, value: undefined, attribute: 'fontColor'})}
            onChange={(value) => this.onLegendItemChange({cgvID, value, attribute: 'fontColor'})}
          / >
        </DataElement>
        <DataElement label='Minimum Arc Length' helpPath='help:sidebar:display:legend:items:min_arc_length'>
          <Slider min={0} max={2} step={0.1} value={legendItemData.minArcLength}
            allowDefault={true}
            isDefault={legendItem.usingDefaultMinArcLength}
            onDefaultClick={() => this.onLegendItemChange({cgvID, value: undefined, attribute: 'minArcLength'})}
            onChange={(value) => this.onLegendItemChange({cgvID, value, attribute: 'minArcLength', redraw: true})} />
        </DataElement>
        <div className='action-buttons'>
          <ImageButton imageName='delete' text='Delete Item' width={100}
          title='Delete Item' onClick={ () => this.setState({deleteID: legendItem.cgvID}) } />
        </div>
      </div>
    );
  }

  renderDeleteDialog() {
    const { deleteID } = this.state;
    if (deleteID) {
      return (
        <LegendDeleteDialog
          legendItemID={deleteID}
          onClose={() => this.setState({deleteID: undefined})}
        />
      );
    }
  }

  onAddLegendItem() {
    const cgv = this.context.cgv;
    const notColors = cgv.legend.items().map( i => i.swatchColor );
    const color = CGColor.getColor(notColors);
    const items = cgv.legend.addItems({
      name: 'New Legend',
      swatchColor: color,
    });
    cgv.draw();
  }

  onRowMouseOver(index) {
    const cgv = this.context.cgv;
    const legendItemData = helpers.itemForIndex(this.props.legendItems, index);
    const legendItem = cgv.objects(legendItemData.cgvID);
    if (legendItem) {
      legendItem.highlight();
    }
  }

  onRowMouseOut() {
    const cgv = this.context.cgv;
    cgv.clear('background');
    cgv.clear('ui');
  }


  setListRef(ref) {
    this.listRef = ref;
  }

  render() {
    const legendItems = helpers.itemsAsArray(this.props.legendItems);
    // const legend = this.props.legend;

    return (
      <div className='LegendPane'>
        <ListItem
          className='Legend'
          mainRenderer={this.legendMainRenderer}
          infoRenderer={this.legendInfoRenderer}
          infoOpen={this.state.legendInfoOpen}
          infoClicked={this.legendInfoClicked}
        />
        <div className='legend-items-list'>
          <VirtualList
            key='items'
            itemCount={legendItems.length}
            items={legendItems}
            dataName={'Legend Items'}
            sortable={true}
            onSortEnd={this.onSortEnd}
            listItemMainRenderer={this.listItemMainRenderer}
            listItemInfoRenderer={this.listItemInfoRenderer}
            listItemID={this.listItemID}
            onRowMouseOver={this.onRowMouseOver}
            onRowMouseOut={this.onRowMouseOut}
            setRef={this.setListRef}
            addAction={(ids)=>{this.onAddLegendItem()}}
          />
        </div>
        {this.renderDeleteDialog()}
      </div>
    );
  }
}

LegendPane.contextType = CGViewContext;

//Connected
const legendPaneMapStateToProps = (state) => ({ legend: state.legend, legendItems: state.legendItems });
const ConnectedLegendPane = connect(legendPaneMapStateToProps, null, null, {forwardRef: true})(LegendPane);

// export default LegendPane;
export { LegendPane, ConnectedLegendPane };

