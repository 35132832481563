// import { changeTheme } from '../proksee-viewer/support/Helpers';
//
// document.addEventListener('DOMContentLoaded', () => {
//   const storage = window.localStorage;
//
//   $('#current-theme').html(storage.getItem('theme'));
//
//   $('#change-theme').click(function() {
//     const currentTheme = storage.getItem('theme');
//     const newTheme = (currentTheme === 'dark') ? 'light' : 'dark';
//     storage.setItem('theme', newTheme);
//     changeTheme(newTheme);
//     $('#current-theme').html(newTheme);
//     return false;
//   });
//
// });
//
