import React from 'react';
// import { CGViewContext } from '../app/CGViewContext';
import './DisplayPane.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "../support/react-tabs.sidebar.css";
import { ConnectedTrackPane } from './TrackPane';
import { ConnectedCaptionPane } from './CaptionPane';
import { ConnectedLegendPane } from './LegendPane';
import { SettingsPane } from './SettingsPane';
import Button from '../presenters/Button';
import MapStyleDialog from './dialogs/MapStyle/MapStyleDialog.js';

class DisplayPane extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tabIndex: props.tabIndex || 0,    // Production
      // tabIndex: props.tabIndex || 1, // Tab for current development
      mapStyleDialogOpen: false,
    }
  }

  setTabIndex(tabIndex) {
    if (tabIndex !== undefined) {
      this.setState({tabIndex});
    }
  }

  openMapStyleDialog() {
    this.setState({mapStyleDialogOpen: true});
  }

  renderMapStyleDialog() {
    return (
        <MapStyleDialog
          okText='Done'
          cancelButtonProps={{ style: { display: 'none' } }}
          onClose={() => this.setState({mapStyleDialogOpen: false})}
        />
    );
  }

  renderMapStyleButton() {
    // const userRole = this.context.userRole;
    // if (userRole !== 'admin') { return; }
    return (
      <Button className='btn-styles-open' width={64} height={20} onClick={() => this.openMapStyleDialog()}>
        Map Style
      </Button>
    );
  }

  render() {
    const { registerPane } = this.props;
    const { mapStyleDialogOpen } = this.state;

    return (
      <div className='DisplayPane'>
        <Tabs className='sub-tabs' selectedIndex={this.state.tabIndex} onSelect={ tabIndex => this.setState({ tabIndex }) } forceRenderTabPanel >

          <div className='display-tabs-and-styles'>
            { this.renderMapStyleButton() }
            <TabList>
              <Tab>Tracks</Tab>
              <Tab>Legend</Tab>
              <Tab>Captions</Tab>
              <Tab>Settings</Tab>
            </TabList>
          </div>

          <TabPanel><ConnectedTrackPane ref={ref => registerPane('tracks', ref, 0, 'display')} /></TabPanel>
          <TabPanel><ConnectedLegendPane ref={ref => registerPane('legend', ref, 1, 'display')} /></TabPanel>
          <TabPanel><ConnectedCaptionPane ref={ref => registerPane('captions', ref, 2, 'display')} /></TabPanel>
          <TabPanel><SettingsPane ref={ref => registerPane('settings', ref, 3, 'display')} /></TabPanel>
        </Tabs>
        {mapStyleDialogOpen && this.renderMapStyleDialog()}
      </div>
    );
  }
          // <TabPanel><SettingsPane /></TabPanel>
}

// DisplayPane.contextType = CGViewContext;

export default DisplayPane;


