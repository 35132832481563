import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Sidebar.css';
import DisplayPane from '../panes/DisplayPane';
import RegionPane from '../panes/RegionPane';
import DataPane from '../panes/DataPane';
import DownloadPane from '../panes/DownloadPane';
import ToolPane from '../panes/ToolPane';
import { ConnectedJobPane } from '../panes/JobPane';
import FavoriteBar from '../containers/FavoriteBar';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "../support/react-tabs.sidebar.css";
import ImageButton from '../presenters/ImageButton';
import sidebarClose from '../images/sidebar-close.png';
import sidebarOpen from '../images/sidebar-open.png';
import iconChevron from '../images/icon-chevron.png';
import { ConnectedJobStatus } from '../containers/JobStatus';

// Only used for admin data panel (TEMP)
import { CGViewContext } from '../app/CGViewContext';

class Sidebar extends React.Component {

  static propTypes = {
    closed:   PropTypes.bool,
    onToggle: PropTypes.func,
  }

  static defaultProps = {
    closed: false,
  }


  constructor(props) {
    super(props);
    this.state = {
      open: !props.closed,
      tabIndex: props.tabIndex || 3, // Paul suggests Tools be the first pane open on launch
      // tabIndex: props.tabIndex || 2, // Tab for current development
    }
    this.panes = {};
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.registerPane = this.registerPane.bind(this);
  }

  // If bool is not provided, the state will be toggled
  toggleSidebar(bool) {
    const open = (bool === undefined) ? !this.state.open : bool;
    this.setState({open});
    if (typeof this.props.onToggle === 'function') {
      this.props.onToggle(open);
    }
  }

  // Only close sidebar if the window is narrow
  autoCloseSideBar() {
    if (window.innerWidth < 576) {
      this.toggleSidebar(false);
    }
  }

  setTabIndex(tabIndex) {
    if (tabIndex !== undefined) {
      this.setState({tabIndex});
    }
  }

  // Currently only considers 2 layers of tabs
  // panes = {
  //   tracks: {
  //     ref: TrackPane,
  //     tabIndex: 1,
  //     parent: 'display'
  //   }
  // }
  selectPane(paneName) {
    console.log(`Set Sidebar Pane: ${paneName}`);
    const pane = this.panes[paneName];
    this.toggleSidebar(true);
    // console.log(pane);
    if (pane) {
      const parent = this.panes[pane.parent];
      if (parent) {
        parent.ref.setTabIndex(pane.tabIndex);
        this.setTabIndex(parent.tabIndex);
      } else {
        this.setTabIndex(pane.tabIndex);
      }
    }
    return pane.ref;
  }

  getPane(paneName) {
    return this.panes[paneName] && this.panes[paneName].ref;
  }

  registerPane(name, ref, tabIndex, parent) {
    this.panes[name] = { ref, tabIndex, parent };
  }

  render() {
    const { open } = this.state;

    const sidebarClass = classNames(
      'Sidebar',
      {open}
    );
    const sidebarToggleClass = classNames(
      'sidebar-toggle',
      {open}
    );
    const toggleImage = open ? sidebarClose : sidebarOpen;

    const userRole = this.context.userRole;

    return (
      <div>
        <ImageButton
          className={sidebarToggleClass}
          onClick={this.toggleSidebar}
          image={toggleImage}
          title='Toggle Sidebar'
        / >
        <div className={sidebarClass}>
          <div className='sidebar-toggle-strip' onClick={ () => this.toggleSidebar() }>
            <div className='toggle'>
              <img src={iconChevron} width='15' height='9' alt='open/close' />
            </div>
            <ConnectedJobStatus />
          </div>
          <div className='inner-container'>
            <div className='tab-container'>
              <Tabs selectedIndex={this.state.tabIndex} onSelect={ tabIndex => this.setState({ tabIndex }) } forceRenderTabPanel >
                <TabList>
                  <Tab>Display</Tab>
                  <Tab>Regions</Tab>
                  {/* { userRole !=  'admin' && <Tab>Download</Tab> } */}
                  <Tab>Data</Tab>
                  <Tab>Tools</Tab>
                  <Tab>Jobs <ConnectedJobStatus /></Tab>
                </TabList>
                <TabPanel><DisplayPane ref={ref => this.registerPane('display', ref, 0)} registerPane={this.registerPane} /></TabPanel>
                <TabPanel><RegionPane ref={ref => this.registerPane('regions', ref, 1)} registerPane={this.registerPane} /></TabPanel>
                {/* { userRole !=  'admin' && <TabPanel><DownloadPane ref={ref => this.registerPane('downloads', ref, 2)} /></TabPanel> } */}
                <TabPanel><DataPane ref={ref => this.registerPane('data', ref, 2)} registerPane={this.registerPane} /></TabPanel>
                <TabPanel><ToolPane ref={ref => this.registerPane('tools', ref, 3)} /></TabPanel>
                <TabPanel><ConnectedJobPane ref={ref => this.registerPane('jobs', ref, 4)} /></TabPanel>
              </Tabs>
            </div>
            { false && <FavoriteBar /> }
          </div>
        </div>
      </div>
    );
  }
}

Sidebar.contextType = CGViewContext;

export default Sidebar;
