import React, { useState } from 'react';
import DataElement from '../../../presenters/DataElement';
import Button from '../../../presenters/Button';
import TextInput from '../../../presenters/TextInput';
import * as helpers from '../../../support/Helpers';
import MapStyle from './MapStyle';
import './MapStyleDialog.css';
import { MapStyleSelection } from './MapStyleSelection';

export default function MapStyleExport({ cgv }) {

  const myMapStyle = new MapStyle({cgv: cgv});

  const [selectedMapStyles, setSelectedMapStyles] = useState(myMapStyle.allStyleKeys);
  const [exportFilename, setExportFilename] = useState('mapStyle.json');

  const handleSelectionChange = (updatedSelection) => {
    setSelectedMapStyles((prevSelectedStyles) => {
      return [...updatedSelection];
    });
  };

  const exportMapStyle = () => {
    const exportMapStyleJSON = myMapStyle.exportJSONForKeys(selectedMapStyles);
    // We want to keep undefeind values as null (e.g. for label color)
    // const replacer = (key, value) => (value === undefined ? null : value);
    // const mapStyleString = JSON.stringify(exportMapStyleJSON, replacer, 2);
    const mapStyleString = JSON.stringify(exportMapStyleJSON, null, 2);
    cgv.io.download(mapStyleString, exportFilename, 'text/json');
  };

  return (
    <div className="MapStyleExport">
      <div className='dialog-header'>
        These are the exportable styles from the current map
      </div>
      <MapStyleSelection mapStyle={myMapStyle} selectedStyleKeys={selectedMapStyles} onSelectionChange={handleSelectionChange} />
      <DataElement label="Export Filename">
        <TextInput
          value={exportFilename}
          type="text"
          onChange={(value) => setExportFilename(value)}
        />
      </DataElement>
      <Button className='btn-export' onClick={exportMapStyle} width="100%" height={40} disabled={!selectedMapStyles.length}>
        Export Selected ({selectedMapStyles.length}/{myMapStyle.allStyleKeys.length}) as a Map Style
      </Button>
    </div>
  );
};
