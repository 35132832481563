import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import Dialog from '../../presenters/Dialog';
import ExternalLink from '../../presenters/ExternalLink';
import DataElement from '../../presenters/DataElement';
import TextInput from '../../presenters/TextInput';
import { connect } from 'react-redux';
import * as helpers from '../../support/Helpers';
import * as CGView from '../../../CGView.js';
import ServerAPI from '../../models/ServerAPI';
import Message from '../../presenters/Message';
import Toast from '../../presenters/Toast';
import { CGViewContext } from '../../app/CGViewContext';

class SnapshotsAddDialog extends Component {

  static contextType = CGViewContext;

  static propTypes = {
    snapshots: PropTypes.shape({
      ids:  PropTypes.array,
      byID: PropTypes.object,
    }).isRequired,
    onClose: PropTypes.func,
  }

  constructor(props, context) {
    super(props, context);
    const snapshotNames = helpers.itemsAsArray(props.snapshots).map( s => s.name);
    const name = CGView.utils.uniqueId('snapshot-', 1, snapshotNames);
    this.state = { valid: true, name };
    this.dialogRef = createRef();
  }

  onCancel() {
    console.log('onCancel');
    this.dialogRef.current.handleCancel();
    this.props.onClose();
  }

  // This code should be kept in sync with onAddSnapshot in SnapshotsPane
  onOk() {
    const { name } = this.state;
    const cgv = this.context.cgv;
    const sidebar = this.context.sidebarRef;
    Message.open({content: "Creating Snapshot..."})
    const Server = new ServerAPI();
    const cgview = JSON.stringify(cgv.io.toJSON());
    const data = { snapshot: {name, cgview} };
    Server.post(Server.URL.snapshotCreate, data)
    .then( response => {
      if (response.ok) {
        Toast.create('Snapshot successfully created', 1000);
        this.dialogRef.current.handleOk();
        this.props.onClose();
        sidebar.selectPane('snapshots');
      } else {
        this.dialogRef.current.showErrors(response.json)
      }
    })
    .finally( () => {
      Message.close();
    })
  }

  renderContents() {
    const { name } = this.state;
    return (
      <div className='SnapshotsAddDialog'>
        <p>
          Snapshots capture the current state of the map, including tracks,
          features, and legends, saving it as a Map JSON file on the Proksee
          server as part of the project. They can be easily restored from the
          Snapshot Panel or shared with others to create a new project. For more
          details, see the <ExternalLink name="Help page"
          link="/help#s.help:sidebar:data:snapshots" size={12} />.
        </p>
        <DataElement label='Snapshot Name'>
          <TextInput  value={name} onChange={ (value) => this.setState({name: value})} />
        </DataElement>
      </div>
    );
  }

  render() {
    return (
      <Dialog
        ref={this.dialogRef}
        onOk={ () => this.onOk() }
        onCancel={ () => this.onCancel() }
        okText='Create Snapshot'
        title='Create Snapshot'
      >
        {this.renderContents()}
      </Dialog>
    );
  }
}

//Connected
const mapStateToProps = (state) => ({ snapshots: state.snapshots });
const ConnectedSnapshotsAddDialog = connect(mapStateToProps, null, null, {forwardRef: true})(SnapshotsAddDialog);

// export default SnapshotsAddDialog;
export { SnapshotsAddDialog, ConnectedSnapshotsAddDialog};
