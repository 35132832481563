import React from 'react';
import ImageButton from '../../../presenters/ImageButton';
import Button from '../../../presenters/Button';
import ButtonGroup from '../../../presenters/ButtonGroup';
import * as helpers from '../../../support/Helpers';
import './MapStyleComponents.css';


export function MapStyleSectionLabel({ label, selectedMapStyles, mapStyleIDs, toggleStyle }) {
  const selectedCount = selectedMapStyles.filter((item) => mapStyleIDs.includes(item)).length;
  const labelName = `${label} (${selectedCount.toLocaleString()}/${mapStyleIDs.length.toLocaleString()})`;
  const selectAll = () => {
    toggleStyle(mapStyleIDs, true);
  }
  const selectNone = () => {
    toggleStyle(mapStyleIDs, false);
  }

  return (
    <div className="MapStyleSectionLabel">
      {labelName}
      <ButtonGroup>
        <Button width={40} height={16} onClick={selectAll} disabled={selectedCount === mapStyleIDs.length} >
          All
        </Button>
        <Button width={40} height={16} onClick={selectNone} disabled={selectedCount === 0} >
          None
        </Button>
      </ButtonGroup>
    </div>
  );
}

export function StyleLegendItem({ style: item, name }) {
  // console.log(item)
  return (
    <div className="StyleLegendItem style-component">
      <div className="style-component-value">
        <ImageButton
          disabled
          active
          title="Decoration"
          size="small"
          imageName={`decoration${helpers.capitalize(item.decoration)}`}
        />
        <div
          className="style-color-swatch"
          style={{ backgroundColor: item.swatchColor }}
        ></div>
      </div>
      <span className="style-component-name">{item.name}</span>
    </div>
  );
};

export function StyleColorOrText({ style: color, name }) {
  if (color) {
    return <StyleColor style={color} name={name} />
  } else {
    return (
      <div className="StyleColor style-component">
        <div className="style-component-value">
          <div className="style-color-text">Feature Color</div>
        </div>
        <span className="style-component-name">{name}</span>
      </div>
    );
  }
};

export function StyleColor({ style: color, name }) {
  return (
    <div className="StyleColor style-component">
      <div className="style-component-value">
        <div className="style-color-swatch" style={{ backgroundColor: color }} ></div>
      </div>
      <span className="style-component-name">{name}</span>
    </div>
  );
};

export function StyleNumber({ style: number, name }) {
  return (
    <div className="StyleNumber style-component">
      <div className="style-component-value">
        {number}
      </div>
      <span className="style-component-name">{name}</span>
    </div>
  );
};

export function StyleBoolean({ style: bool, name }) {
  return (
    <div className="StyleBoolean style-component">
      <div className="style-component-value">
        {bool ? 'Yes' : 'No'}
      </div>
      <span className="style-component-name">{name}</span>
    </div>
  );
};

export function StyleFont({ style: font, name }) {
  const familyMap = {
    'monospace': 'Monospace',
    'serif': 'Serif',
    'sans-serif': 'Sans Serif',
  }
  const fontParts = font.split(',').map(part => part.trim());
  const fontFamily = fontParts[0] || 'Unknown';
  const fontStyle = fontParts[1] || 'Unknown';
  const activeBold = fontStyle.includes('bold');
  const activeItalic = fontStyle.includes('italic');
  const fontSize = fontParts[2] || '-';
  return (
    <div className="StyleFont style-component">
      <div className="style-component-value">
        <div className="style-font-family">{familyMap[fontFamily]}</div>
        <div className="style-font-style-size">
          <ImageButton
            disabled
            active={activeBold}
            title="Bold"
            size="small"
            imageName='bold'
          />
          <ImageButton
            disabled
            active={activeItalic}
            title="Italic"
            size="small"
            imageName='italic'
          />
          <div className="style-font-size">{fontSize}</div>
        </div>
      </div>
      <span className="style-component-name">{name}</span>
    </div>
  );
};